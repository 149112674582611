import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { MenuService } from "../services/menu.service";
import { Item } from "src/models/item";
import { Router } from "@angular/router";
import { beResponsive } from "../app-routing.module";

@Component({
  selector: "app-landing-components",
  templateUrl: "./landing-components.component.html",
  styleUrls: ["./landing-components.component.scss"],
})
export class LandingComponentsComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("inicio") inicioElement: ElementRef;
  @ViewChild("servicios") serviciosElement: ElementRef;
  @ViewChild("portafolio") portafolioElement: ElementRef;
  @ViewChild("nosotros") nosotrosElement: ElementRef;
  @ViewChild("procesos") procesosElement: ElementRef;
  @ViewChild("contactenos") contactenosElement: ElementRef;
  start = undefined;
  homeOffset: number;
  servicesOffset: number;
  portafolioOffset: number;
  menuItems: Item[];
  nosotrosOffset: number;
  ProcesosOffset: number;
  contactenosOffset: number;
  offset: number;
  currentActive = 0;
  constructor(private menuService: MenuService, private router: Router) {}

  ngOnInit() {
    this.offset = beResponsive();
    this.getMenuItems();
  }

  ngAfterViewInit() {
    this.homeOffset = this.inicioElement.nativeElement.offsetTop;
    this.servicesOffset = this.serviciosElement.nativeElement.offsetTop;
    this.portafolioOffset = this.portafolioElement.nativeElement.offsetTop;
    this.nosotrosOffset = this.nosotrosElement.nativeElement.offsetTop;
    this.ProcesosOffset = this.procesosElement.nativeElement.offsetTop;
    this.contactenosOffset = this.contactenosElement.nativeElement.offsetTop;
  }

  ngOnDestroy() {
    this.menuItems.map((item) => {
      item.active = false;
    });
  }

  goTop() {
    this.start = undefined;
    const animate: any = (timestamp) => {
      if (!this.start) {
        this.start = timestamp;
      }
      const step = this.start - timestamp + window.pageYOffset;
      // console.log("windowy: " + window.pageYOffset);
      // console.log(step);
      window.scrollTo(0, Math.min(step, window.pageYOffset));
      if (step >= 0) {
        window.requestAnimationFrame(animate);
      }
    };
    window.requestAnimationFrame(animate);
  }

  getMenuItems() {
    this.menuService
      .getMenuItems()
      .subscribe((items) => (this.menuItems = items));
  }

  @HostListener("window:scroll")
  checkOffsetTop() {
    this.ngAfterViewInit();
    if (
      window.pageYOffset >= this.homeOffset &&
      window.pageYOffset < this.servicesOffset - this.offset
    ) {
      this.currentActive = 1;
    } else if (
      window.pageYOffset >= this.servicesOffset - this.offset &&
      window.pageYOffset < this.portafolioOffset - this.offset
    ) {
      this.currentActive = 2;
    } else if (
      window.pageYOffset >= this.portafolioOffset - this.offset &&
      window.pageYOffset < this.nosotrosOffset - this.offset
    ) {
      this.currentActive = 3;
    } else if (
      window.pageYOffset >= this.nosotrosOffset - this.offset &&
      window.pageYOffset < this.ProcesosOffset - this.offset
    ) {
      this.currentActive = 4;
    } else if (
      window.pageYOffset >= this.ProcesosOffset - this.offset &&
      window.pageYOffset < this.contactenosOffset - this.offset
    ) {
      this.currentActive = 5;
    } else if (window.pageYOffset >= this.contactenosOffset - this.offset) {
      this.currentActive = 6;
    } else {
      this.currentActive = 0;
    }
    this.menuItems.map((item, idx) => {
      if (idx + 1 === this.currentActive) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
  }
}
