import {
  Component,
  OnInit,
  ɵConsole,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
  OnDestroy,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { HomeImage } from "src/models/home-image";
import { HomeService } from "src/app/services/home.service";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren("background") background: QueryList<ElementRef>;
  constructor(private homeService: HomeService, private renderer: Renderer2) {}
  homeImages: HomeImage[];
  backgroundDivs: ElementRef[] = [];
  idToHide = 0;
  carousel;
  start = undefined;
  ngOnInit() {
    this.getHomeImages();
  }

  ngAfterViewInit(): void {
    this.calculateBackgroundDivs();

    // console.log(this.background);
    // let backgroundCarousel = this.background.nativeElement.ownerDocument.defaultView
    //   .getComputedStyle(this.background.nativeElement, null)
    //   .getPropertyValue("background-image");
    // console.log(backgroundCarousel);
    // let count = 0;
    // this.carousel = setInterval(() => {
    //   let nextImage = undefined;
    //   if (this.homeImages.length > 0) {
    //     nextImage = this.homeImages[count].url;
    //   }
    //   count++;
    //   if (count >= this.homeImages.length) {
    //     count = 0;
    //   }
    //   this.renderer.setStyle(
    //     this.background.nativeElement,
    //     "background-image",
    //     `url(${nextImage})`
    //   );
    //   backgroundCarousel = this.background.nativeElement.ownerDocument.defaultView
    //     .getComputedStyle(this.background.nativeElement, null)
    //     .getPropertyValue("background-image");
    //   console.log(backgroundCarousel);
    // }, 2000);
    this.carousel = setInterval(() => {
      if (this.idToHide >= this.homeImages.length - 1) {
        this.idToHide = 0;
      } else {
        this.idToHide++;
      }
    }, 6000);
  }

  calculateBackgroundDivs() {
    setTimeout(() => {
      this.background.map((p) => {
        this.backgroundDivs.push(p);
      });
      this.changeBackgroundImages();
    }, 0);
  }

  changeBackgroundImages() {
    if (this.homeImages.length > 0) {
      this.backgroundDivs.map((div) => {
        if (this.homeImages[div.nativeElement.id - 1]) {
          this.renderer.setStyle(
            div.nativeElement,
            "background-image",
            `url(${this.homeImages[div.nativeElement.id - 1].url})`
          );
        }
      });
    }
  }

  onConocenos() {
    this.start = undefined;
    if (this.backgroundDivs) {
      const animate: any = (timestamp) => {
        if (!this.start) {
          this.start = timestamp;
        }
        const step = timestamp - this.start;
        window.scrollTo(
          0,
          Math.min(
            step,
            this.backgroundDivs[0].nativeElement.parentElement.parentElement
              .scrollHeight - 81
          )
        );
        if (
          step <
          this.backgroundDivs[0].nativeElement.parentElement.parentElement
            .scrollHeight -
            81
        ) {
          window.requestAnimationFrame(animate);
        }
      };
      window.requestAnimationFrame(animate);

      // window.scroll({
      //   top:
      //     this.backgroundDivs[0].nativeElement.parentElement.parentElement
      //       .scrollHeight - 81,
      //   behavior: "smooth",
      // });
    }
    // if (this.homeImages.length > 0) {
    //   this.backgroundDivs.map((div) => {
    //     if (this.homeImages[div.nativeElement.id - 1]) {
    //       div.nativeElement.parentElement.parentElement.scrollTop =
    //         div.nativeElement.scrollHeight;
    //     }
    //   });
    // }
  }

  ngOnDestroy() {
    clearInterval(this.carousel);
  }

  getHomeImages() {
    this.homeService.getHomeImages().subscribe(
      (items) =>
        (this.homeImages = items.sort((a, b) => {
          if (a.id > b.id) {
            return 1;
          } else {
            return -1;
          }
        }))
    );
  }
}
