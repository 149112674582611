<div class="container">
  <div class="title">
    <span>Servicios</span>
  </div>
  <!-- <div class="description">
    <span
      >Lorem qui cillum cupidatat excepteur nulla exercitation ea aliquip tempor
      fugiat commodo excepteur culpa.</span
    >
  </div> -->
  <div class="servicios-main-container">
    <div class="servicios-container" *ngFor="let servicio of servicios">
      <div class="icon-container">
        <img
          class="servicio-icon"
          src="{{ servicio?.iconUrl }}"
          alt="{{ servicio.title }}"
        />
      </div>
      <div class="servicio">
        <div class="servicio-title">
          <span>{{ servicio.title }}</span>
        </div>
        <div class="servicio-descripcion">
          <span>{{ servicio.description }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
