<div class="container">
  <div class="title">
    <span>Nosotros</span>
  </div>
  <div class="paragraph">
    <div class="body">
      <!-- <div class="header">
        <p>Veniam cupidatat et proident ipsum commodo duis irure culpa.</p>
      </div> -->
      <div class="text">
        <p>
          María González es un estudio de diseño interior que se dedica a la
          creación de proyectos de interiorismo, conformado por un equipo
          multidisciplinar que busca integrar el arte, el marketing, la
          digitalización 3D, el diseño de mobiliario y la implementación de la
          tecnología para crear un enfoque único y espacios con identidad,
          obteniendo como resultado conceptos, funcionales e innovadores.
        </p>
        <p>
          Estamos apasionados por los nuevos cambios que se viven día a día, por
          eso nuestra labor es tomar esos cambios y convertirlos en
          experiencias, sensaciones y recuerdos, por medio de un diseño más
          humano.
        </p>
      </div>
    </div>
    <div class="image-container">
      <img
        src="../../../assets/nosotros/nosotros.jpg"
        class="image"
        alt="Nosotros"
      />
    </div>
  </div>
</div>
