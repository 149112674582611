import { Component, OnInit } from "@angular/core";
import { Servicios } from "../../models/servicios";
import { ServiciosService } from "../services/servicios.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  servicios: Servicios[];
  constructor(private serviciosService: ServiciosService) {}

  ngOnInit() {
    this.getServicios();
  }

  getServicios() {
    this.serviciosService
      .getServicios()
      .subscribe((servicios) => (this.servicios = servicios));
  }
}
