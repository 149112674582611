import {
  Component,
  OnInit,
  AfterViewInit,
  Inject,
  ViewChild,
  ElementRef,
  OnDestroy,
  Input,
} from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment as env } from "../../../environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ContactService } from "src/app/services/contact.service";
import { trigger, transition, style, animate } from "@angular/animations";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { Country } from "src/models/country";
import { Gender } from "src/models/gender";
declare const grecaptcha: any;
declare global {
  interface Window {
    dataLayer: any;
    grecaptcha: any;
  }
}
@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
  animations: [
    trigger("ContactfadeIn", [
      transition(":enter", [
        style({ opacity: "0" }),
        animate("1s ease-out", style({ opacity: "1" })),
      ]),
      transition(":leave", [
        style({ opacity: "1" }),
        animate("0.5s ease-out", style({ opacity: "0" })),
      ]),
    ]),
    trigger("Contactap", [
      transition(":enter", [
        style({ transform: "translateX(20%)" }),
        animate("1s ease-out", style({ transform: "translateX(0%)" })),
      ]),
    ]),
  ],
})
export class ContactComponent implements OnInit {
  @ViewChild("recaptcha") recaptchaElement: ElementRef;
  @ViewChild("recaptcha2") recaptchaElement2: ElementRef;
  _name = "";

  @Input()
  set name(name: string) {
    this._name = (name && name.trim()) || "primary";
  }
  contacto: FormGroup;
  enviar: boolean;
  pressed: boolean;
  email: string;
  phone: string;
  whatsapp: string;
  gender: string;
  year: number;
  country: string;
  city: string;
  siteKeyCaptcha = env.clientKey;
  siteKeyCaptcha2 = env.clientKey2;
  isRobot: boolean;
  captchaString = "";
  captchaId: any;
  captchaId2: any;
  count = 0;
  private widgetId: number;

  constructor(
    private contactService: ContactService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private element: ElementRef
  ) {
    this.enviar = false;
    this.pressed = false;
    this.createForm();
    // (<any>window).onloadCallback;
  }

  ngOnInit() {
    this.email = env.email;
    this.phone = env.phone;
    this.whatsapp = env.whatsapp;
    this.isRobot = true;
    this.registerReCaptchaCallback();
    this.addScript();
    // window.grecaptchaCallback = this.renderReCaptcha;
    // global.gre
    // global.grecaptchaCallback = this.renderReCaptcha;
    // (window as any).grecaptchaCallback = this.renderReCaptcha;
  }

  registerReCaptchaCallback() {
    if (this._name === "secondary") {
      window["reCaptchaLoad2"] = () => {
        const config = {
          sitekey: this.siteKeyCaptcha,
          callback: (response) => {
            this.isRobot = false;
            this.captchaString = response;
          },
          "expired-callback": () => {
            this.isRobot = false;
            this.captchaString = "";
          },
        };
        this.widgetId = this.render(
          this.recaptchaElement2.nativeElement,
          config
        );
      };
    } else {
      window["reCaptchaLoad"] = () => {
        const config = {
          sitekey: this.siteKeyCaptcha,
          callback: (response) => {
            this.isRobot = false;
            this.captchaString = response;
          },
          "expired-callback": () => {
            this.isRobot = false;
            this.captchaString = "";
          },
        };
        this.widgetId = this.render(
          this.recaptchaElement.nativeElement,
          config
        );
      };
    }
  }

  private render(element: HTMLElement, config): number {
    return grecaptcha.render(element, config);
  }

  addScript() {
    if (this._name === "secondary") {
      let script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad2&render=explicit`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    } else {
      let script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad&render=explicit`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }

  addRecaptchaScript() {
    (<any>window).grecaptchaCallback = () => {
      if (!window["grecaptcha"]) {
        setTimeout(() => {
          this.renderReCaptcha();
        }, 500);
      } else {
        this.renderReCaptcha();
      }
    };

    // if (this._name === "secondary") {
    //   (function (d, s, id, obj) {
    //     var js,
    //       fjs = d.getElementsByTagName(s)[0];
    //     if (d.getElementById(id)) {
    //       obj.renderReCaptcha();
    //       return;
    //     }
    //     js = d.createElement(s);
    //     js.id = id;
    //     js.src =
    //       "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback2&render=explicit";
    //     fjs.parentNode.insertBefore(js, fjs);
    //   })(document, "script", "recaptcha-jssdk2", this);
    // } else {
    //   (function (d, s, id, obj) {
    //     var js,
    //       fjs = d.getElementsByTagName(s)[0];
    //     if (d.getElementById(id)) {
    //       obj.renderReCaptcha();
    //       return;
    //     }
    //     js = d.createElement(s);
    //     js.id = id;
    //     js.src =
    //       "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&render=explicit";
    //     fjs.parentNode.insertBefore(js, fjs);
    //   })(document, "script", "recaptcha-jssdk", this);
    // }
  }

  renderReCaptcha() {
    if (this._name === "secondary") {
      if (window["grecaptcha"]) {
        this.captchaId = window["grecaptcha"].render(
          this.recaptchaElement2.nativeElement,
          {
            sitekey: this.siteKeyCaptcha,
            callback: (response) => {
              this.isRobot = false;
              this.captchaString = response;
            },
          }
        );
      }
    } else {
      if (window["grecaptcha"]) {
        this.captchaId2 = window["grecaptcha"].render(
          this.recaptchaElement.nativeElement,
          {
            sitekey: this.siteKeyCaptcha2,
            callback: (response) => {
              this.isRobot = false;
              this.captchaString = response;
            },
          }
        );
      }
    }
  }

  createForm() {
    this.contacto = this.fb.group({
      name: ["", Validators.required],
      email: ["", Validators.required],
      phone: ["", Validators.pattern("^[0-9]*$")],
      message: [""],
      privacy: [false, Validators.required],
      newsletter: [false],
      gender: [""],
      city: [""],
      year: ["", Validators.pattern("^[0-9]*$")],
      country: [""],
    });
  }

  doSend() {
    if (this.captchaString.length > 0) {
      if (this.contacto.valid) {
        this.contacto
          .get("phone")
          .setValue(this.contacto.get("phone").value.trim());
        if (this.contacto.get("privacy").value) {
          this.sendInformation();
          if (this.contacto.get("newsletter").value) {
            this.openMoreInfoDialog();
          }
        }
      } else {
        this.contacto.markAllAsTouched();
      }
    }
  }

  sendInformation() {
    this.enviar = true;
    this.contactService.sendInformation(this.contacto).subscribe((data) => {
      // console.log(data);
    });
  }
  subscribe() {
    this.enviar = true;
    this.contactService
      .subscribe(this.contacto, this.captchaString)
      .subscribe((data) => {
        if (data["success"]) {
          // console.log("exitoso papa");
          if (data["updated"]) {
            window.dataLayer.push({ event: "contact-updated" });
            this.openDialog();
          } else {
            window.dataLayer.push({ event: "contact-new" });
            // console.log("nuevo, papa");
          }
        } else {
          window.dataLayer.push({ event: "contact-error" });
          // console.log(data);
        }
      });
  }

  openDialog(): void {
    // console.log(this.contacto.get("name").value);
    const dialogRef = this.dialog.open(ConfirmDialog, {
      width: "380px",
      data: { name: this.contacto.get("name").value },
    });
  }

  openMoreInfoDialog(): void {
    const dialogRef = this.dialog.open(MoreInfoDialog, {
      width: "430px",
      data: { name: this.contacto.get("name").value },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.gender = result ? result.gender : undefined;
      this.city = result ? result.city : undefined;
      this.year = result ? result.year : undefined;
      this.country = result ? result.country : undefined;
      this.contacto.get("gender").setValue(this.gender);
      this.contacto.get("city").setValue(this.city);
      this.contacto.get("year").setValue(this.year);
      this.contacto.get("country").setValue(this.country);
      this.subscribe();
    });
  }

  captchaResponse(response) {
    this.captchaString = response;
  }

  clearValidator() {
    this.contacto.controls;
  }

  onKeyUp(event: any) {
    if (event.key === " ") {
      event.target.value = event.target.value.slice(0, -1);
    }
  }
  focusOut(event: any) {
    event.target.value = event.target.value.trim();
    const inputName = event.target.attributes["formcontrolname"].nodeValue;
    this.contacto.get(inputName).setValue(event.target.value);
  }
}

@Component({
  selector: "confirm-dialog",
  templateUrl: "dialog.html",
})
export class ConfirmDialog {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
  }
  goOut(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: "more-info-dialog",
  templateUrl: "moreinfo.html",
})
export class MoreInfoDialog {
  countries: Country[];
  genders: Gender[];
  selectedCountries: Country[];
  recomendados: Country[] = [
    { name: "Colombia", code: "CO" },
    { name: "Mexico", code: "MX" },
    { name: "Peru", code: "PE" },
    { name: "Argentina", code: "AR" },
    { name: "Venezuela", code: "VE" },
  ];
  constructor(
    public dialogRef: MatDialogRef<MoreInfoDialog>,
    private contactService: ContactService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.getCountries();
    this.getGenders();
  }

  getCountries() {
    this.contactService.getCountries().subscribe((countries) => {
      this.countries = countries;
      this.selectedCountries = this.recomendados;
    });
  }
  getGenders() {
    this.contactService.getGenders().subscribe((genders) => {
      this.genders = genders;
    });
  }

  goOut(): void {
    this.dialogRef.close();
  }

  onKeyup(event) {
    if (event.target.value.length === 0) {
      this.selectedCountries = this.recomendados;
    } else {
      this.selectedCountries = this.search(event.target.value).slice(0, 5);
    }
  }
  search(value: string) {
    let filter = value.toLowerCase();
    return this.countries.filter((option) =>
      option.name.toLowerCase().includes(filter)
    );
  }
}
