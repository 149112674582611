import {
  Component,
  OnInit,
  QueryList,
  ElementRef,
  ViewChildren,
  AfterViewInit,
  ViewChild,
  ViewContainerRef,
  Renderer2,
  HostListener,
} from "@angular/core";
import { ProcesosService } from "src/app/services/procesos.service";
import { Procesos } from "src/models/procesos";
import { debug } from "util";
import { StepperSelectionEvent } from "@angular/cdk/stepper";

@Component({
  selector: "app-procesos",
  templateUrl: "./procesos.component.html",
  styleUrls: ["./procesos.component.scss"],
})
export class ProcesosComponent implements OnInit, AfterViewInit {
  constructor(
    private procesosService: ProcesosService,
    private renderer: Renderer2
  ) {}
  @ViewChildren("procesosDiv") procesosDiv: QueryList<ElementRef>;
  @ViewChild("container", { static: true, read: ViewContainerRef })
  container: ViewContainerRef;
  procesos: Procesos[];
  active: number;
  xStart: number;
  xEnd: number;
  xOffset: number;
  totalOffset: number;
  widthByProcess: number;
  realProcesos: Procesos[];
  mouseDown: boolean;
  startx: number;
  starty: number;
  diffx: number;
  diffy: number;
  el: any;
  trigger: number;
  yStart: number;
  shouldScroll: boolean;
  scrollTrigger: number;
  xStarted: boolean;
  shouldScrollX: boolean;
  start = undefined;
  req: any;
  xPageEnd: number;
  minimalMove: number;
  speed: number;

  @HostListener("document:mouseup", ["$event"])
  onMouseUp(e) {
    if (this.mouseDown) {
      if (!e) {
        e = window.event;
      }
      this.mouseDown = false;
      this.shouldScroll = false;
      this.xEnd = e.clientX;
      this.xPageEnd = e.screenX;
      this.renderer.removeClass(this.el, "grab");
      this.calculateShift();
    }
  }

  @HostListener("document:touchend", ["$event"])
  onTouchEnd(e) {
    if (this.mouseDown) {
      if (!e) {
        e = window.event;
      }
      this.mouseDown = false;
      this.shouldScroll = false;
      this.xEnd = e.changedTouches[0].clientX;
      this.xPageEnd = e.changedTouches[0].screenX;
      this.renderer.removeClass(this.el, "grab");
      this.calculateShift();
    }
  }

  @HostListener("document:mousemove", ["$event"])
  onMouseMove(e) {
    if (this.mouseDown === true) {
      if (!e) {
        e = window.event;
      }
      this.diffx = this.startx - (e.clientX + this.el.scrollLeft);
      this.diffy = this.starty - (e.clientY + this.el.scrollTop);
      let actualTranslate = 0;
      let regex = window
        .getComputedStyle(this.procesosDiv.first.nativeElement.parentElement)
        .getPropertyValue("transform")
        .match(/(-?[0-9\.]+)/g);
      if (regex.length > 1) {
        actualTranslate = parseInt(regex[4]);
      }

      if (Math.abs(this.yStart - e.clientY) > this.diffx) {
        if (Math.abs(this.yStart - e.clientY) > this.scrollTrigger) {
          if (!this.xStarted) {
            this.shouldScrollX = true;
          }
        }
      }
      if (this.shouldScroll) {
        window.scroll(0, this.diffy);
      }
      if (
        Math.abs(this.yStart - e.clientY) > this.scrollTrigger &&
        !this.shouldScroll
      ) {
        window.scroll(0, this.diffy);
        this.shouldScroll = true;
      }
      if (!this.shouldScrollX && this.xStarted) {
        this.procesosDiv.first.nativeElement.parentElement.style.transform =
          "translateX(" + (actualTranslate - this.diffx) + "px)";
        this.startx -= this.diffx;
      }
      if (
        Math.abs(this.diffx) > this.minimalMove &&
        !this.xStarted &&
        !this.shouldScrollX
      ) {
        this.xStarted = true;
        this.procesosDiv.first.nativeElement.parentElement.style.transform =
          "translateX(" + (actualTranslate - this.diffx) + "px)";
        this.startx -= this.diffx;
      }
    }
  }

  @HostListener("document:touchmove", ["$event"])
  onTouchMove(e) {
    if (this.mouseDown === true) {
      if (!e) {
        e = window.event;
      }
      this.diffx = this.startx - (e.touches[0].clientX + this.el.scrollLeft);
      this.diffy = this.starty - (e.touches[0].clientY + this.el.scrollTop);
      let actualTranslate = 0;
      let regex = window
        .getComputedStyle(this.procesosDiv.first.nativeElement.parentElement)
        .getPropertyValue("transform")
        .match(/(-?[0-9\.]+)/g);
      if (regex.length > 1) {
        actualTranslate = parseInt(regex[4]);
      }

      if (Math.abs(this.yStart - e.touches[0].clientY) > this.diffx) {
        if (Math.abs(this.yStart - e.touches[0].clientY) > this.scrollTrigger) {
          if (!this.xStarted) {
            this.shouldScrollX = true;
          }
        }
      }
      if (this.shouldScroll) {
        window.scroll(0, this.diffy);
      }
      if (
        Math.abs(this.yStart - e.touches[0].clientY) > this.scrollTrigger &&
        !this.shouldScroll
      ) {
        window.scroll(0, this.diffy);
        this.shouldScroll = true;
      }
      if (!this.shouldScrollX && this.xStarted) {
        this.procesosDiv.first.nativeElement.parentElement.style.transform =
          "translateX(" + (actualTranslate - this.diffx) + "px)";
        this.startx -= this.diffx;
      }
      if (
        Math.abs(this.diffx) > this.minimalMove &&
        !this.xStarted &&
        !this.shouldScrollX
      ) {
        this.xStarted = true;
        this.procesosDiv.first.nativeElement.parentElement.style.transform =
          "translateX(" + (actualTranslate - this.diffx) + "px)";
        this.startx -= this.diffx;
      }
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    setTimeout(() => {
      this.el.scrollLeft = 0;
      this.widthByProcess = parseInt(this.el.parentElement.scrollWidth, 10);
      this.procesosDiv.first.nativeElement.parentElement.style.transform =
        "translateX(-" + this.widthByProcess * this.active + "px)";
    }, 0);
  }

  ngOnInit() {
    this.speed = 0.5; //the lower, the faster
    this.trigger = 0.15;
    this.shouldScrollX = false;
    this.scrollTrigger = 20;
    this.shouldScroll = false;
    this.xStarted = false;
    this.minimalMove = 10;
    this.getProcesos();
  }

  getProcesos() {
    this.procesosService.getProcesos().subscribe((procesos) => {
      this.procesos = Array.from(procesos);
      this.realProcesos = Array.from(new Set(procesos));
      if (
        this.procesos[0].id !== this.realProcesos[this.procesos.length - 1].id
      ) {
        this.procesos.unshift(this.realProcesos[this.procesos.length - 1]);
      }
      if (
        this.procesos[this.procesos.length - 1].id !== this.realProcesos[0].id
      ) {
        this.procesos.push(this.realProcesos[0]);
      }
    });
  }

  ngAfterViewInit() {
    this.calculateSerializedPanes();
  }

  calculateSerializedPanes() {
    setTimeout(() => {
      this.el = this.procesosDiv.first.nativeElement.parentElement.parentElement;
      this.active = this.realProcesos
        .map((proceso) => proceso.id)
        .sort((a, b) => {
          if (a > b) return 1;
          if (b > a) return -1;
          return 0;
        })[0];
      this.totalOffset = parseInt(
        this.procesosDiv.first.nativeElement.parentElement.parentElement
          .scrollWidth,
        10
      );
      this.widthByProcess = parseInt(this.el.parentElement.scrollWidth, 10);
      this.procesosDiv.first.nativeElement.parentElement.style.transform =
        "translateX(-" + this.widthByProcess + "px)";
    }, 0);
  }

  calculateShift() {
    if (this.procesosDiv) {
      this.start = undefined;
      this.el.scrollLeft = 0;
      let actualTranslate = 0;
      const realOffsetByActualTranslate = this.widthByProcess * this.active;
      const regex = window
        .getComputedStyle(this.procesosDiv.first.nativeElement.parentElement)
        .getPropertyValue("transform")
        .match(/(-?[0-9\.]+)/g);
      if (regex.length > 1) {
        actualTranslate = parseInt(regex[4]);
      }
      if (this.xStart - this.xEnd > this.widthByProcess * this.trigger) {
        if (this.procesos.length > this.realProcesos.length) {
          this.active = this.active + 1;
          if (this.active > this.realProcesos.length) {
            this.active = this.realProcesos
              .map((proceso) => proceso.id)
              .sort((a, b) => {
                if (a > b) return 1;
                if (b > a) return -1;

                return 0;
              })[0];
            const animate: any = (timestamp) => {
              if (!this.start) {
                this.start = timestamp;
              }
              const step =
                timestamp - this.start - actualTranslate * this.speed;
              this.procesosDiv.first.nativeElement.parentElement.style.transform =
                "translateX(-" +
                Math.min(
                  step / this.speed,
                  this.widthByProcess * (this.procesosDiv.length - 1)
                ) +
                "px)";
              if (
                step <
                this.widthByProcess * (this.procesosDiv.length - 1) * this.speed
              ) {
                this.req = window.requestAnimationFrame(animate);
              } else {
                this.procesosDiv.first.nativeElement.parentElement.style.transform =
                  "translateX(-" + this.widthByProcess + "px)";
              }
            };
            this.req = window.requestAnimationFrame(animate);
          } else {
            const animate: any = (timestamp) => {
              if (!this.start) {
                this.start = timestamp;
              }
              const step =
                timestamp - this.start - actualTranslate * this.speed;
              this.procesosDiv.first.nativeElement.parentElement.style.transform =
                "translateX(-" +
                Math.min(step / this.speed, this.widthByProcess * this.active) +
                "px)";
              if (step < this.widthByProcess * this.active * this.speed) {
                this.req = window.requestAnimationFrame(animate);
              }
            };
            this.req = window.requestAnimationFrame(animate);
          }
        }
      } else if (this.xEnd - this.xStart > this.widthByProcess * this.trigger) {
        this.active = this.active - 1;
        if (this.active <= 0) {
          this.active = this.realProcesos[this.realProcesos.length - 1].id;
          const animate: any = (timestamp) => {
            if (!this.start) {
              this.start = timestamp;
            }
            const step = this.start - timestamp - actualTranslate * this.speed;
            this.procesosDiv.first.nativeElement.parentElement.style.transform =
              "translateX(-" + Math.max(step / this.speed, 0) + "px)";
            if (step >= 0) {
              this.req = window.requestAnimationFrame(animate);
            } else {
              this.procesosDiv.first.nativeElement.parentElement.style.transform =
                "translateX(-" + this.widthByProcess * this.active + "px)";
            }
          };
          this.req = window.requestAnimationFrame(animate);
        } else {
          const animate: any = (timestamp) => {
            if (!this.start) {
              this.start = timestamp;
            }
            const step = this.start - timestamp - actualTranslate * this.speed;
            this.procesosDiv.first.nativeElement.parentElement.style.transform =
              "translateX(-" +
              Math.max(step / this.speed, this.widthByProcess * this.active) +
              "px)";
            if (step >= this.widthByProcess * this.active * this.speed) {
              this.req = window.requestAnimationFrame(animate);
            }
          };
          this.req = window.requestAnimationFrame(animate);
        }
      } else {
        let shouldIEnter = Math.abs(
          Math.abs(actualTranslate) - Math.abs(realOffsetByActualTranslate)
        );
        if (Math.abs(actualTranslate) > realOffsetByActualTranslate) {
          if (
            this.active ===
            this.realProcesos
              .map((proceso) => proceso.id)
              .sort((a, b) => {
                if (a > b) return 1;
                if (b > a) return -1;

                return 0;
              })[0]
          ) {
            if (shouldIEnter < this.widthByProcess) {
              const animate: any = (timestamp) => {
                if (!this.start) {
                  this.start = timestamp;
                }
                const step =
                  this.start - timestamp - actualTranslate * this.speed;
                this.procesosDiv.first.nativeElement.parentElement.style.transform =
                  "translateX(-" +
                  Math.max(
                    step / this.speed,
                    this.widthByProcess * this.active
                  ) +
                  "px)";
                if (step >= this.widthByProcess * this.active * this.speed) {
                  this.req = window.requestAnimationFrame(animate);
                }
              };
              this.req = window.requestAnimationFrame(animate);
            } else if (shouldIEnter >= this.widthByProcess) {
              const animate: any = (timestamp) => {
                if (!this.start) {
                  this.start = timestamp;
                }
                const step =
                  timestamp - this.start - actualTranslate * this.speed;
                this.procesosDiv.first.nativeElement.parentElement.style.transform =
                  "translateX(-" +
                  Math.min(
                    step / this.speed,
                    this.widthByProcess * (this.procesosDiv.length - 1)
                  ) +
                  "px)";
                if (
                  step <
                  this.widthByProcess *
                    (this.procesosDiv.length - 1) *
                    this.speed
                ) {
                  this.req = window.requestAnimationFrame(animate);
                } else {
                  this.procesosDiv.first.nativeElement.parentElement.style.transform =
                    "translateX(-" + this.widthByProcess + "px)";
                }
              };
              this.req = window.requestAnimationFrame(animate);
            } else {
              const animate: any = (timestamp) => {
                if (!this.start) {
                  this.start = timestamp;
                }
                const step =
                  this.start - timestamp - actualTranslate * this.speed;
                this.procesosDiv.first.nativeElement.parentElement.style.transform =
                  "translateX(-" +
                  Math.max(
                    step / this.speed,
                    this.widthByProcess * this.active
                  ) +
                  "px)";
                if (step >= this.widthByProcess * this.active * this.speed) {
                  this.req = window.requestAnimationFrame(animate);
                }
              };
              this.req = window.requestAnimationFrame(animate);
            }
          } else {
            const animate: any = (timestamp) => {
              if (!this.start) {
                this.start = timestamp;
              }
              const step =
                this.start - timestamp - actualTranslate * this.speed;
              this.procesosDiv.first.nativeElement.parentElement.style.transform =
                "translateX(-" +
                Math.max(step / this.speed, this.widthByProcess * this.active) +
                "px)";
              if (step >= this.widthByProcess * this.active * this.speed) {
                this.req = window.requestAnimationFrame(animate);
              }
            };
            this.req = window.requestAnimationFrame(animate);
          }
        } else if (Math.abs(actualTranslate) < realOffsetByActualTranslate) {
          if (shouldIEnter > this.widthByProcess) {
            if (
              this.active ===
              this.realProcesos
                .map((proceso) => proceso.id)
                .sort((a, b) => {
                  if (a > b) return 1;
                  if (b > a) return -1;

                  return 0;
                })[this.realProcesos.length - 1]
            ) {
              const animate: any = (timestamp) => {
                if (!this.start) {
                  this.start = timestamp;
                }
                const step =
                  this.start - timestamp - actualTranslate * this.speed;
                this.procesosDiv.first.nativeElement.parentElement.style.transform =
                  "translateX(-" + Math.max(step / this.speed, 0) + "px)";
                if (step >= 0) {
                  this.req = window.requestAnimationFrame(animate);
                } else {
                  this.procesosDiv.first.nativeElement.parentElement.style.transform =
                    "translateX(-" + this.widthByProcess * this.active + "px)";
                }
              };
              this.req = window.requestAnimationFrame(animate);
            } else {
              const animate: any = (timestamp) => {
                if (!this.start) {
                  this.start = timestamp;
                }
                const step =
                  timestamp - this.start - actualTranslate * this.speed;
                this.procesosDiv.first.nativeElement.parentElement.style.transform =
                  "translateX(-" +
                  Math.min(
                    step / this.speed,
                    this.widthByProcess * this.active
                  ) +
                  "px)";
                if (step < this.widthByProcess * this.active * this.speed) {
                  this.req = window.requestAnimationFrame(animate);
                }
              };
              this.req = window.requestAnimationFrame(animate);
            }
          } else {
            const animate: any = (timestamp) => {
              if (!this.start) {
                this.start = timestamp;
              }
              const step =
                timestamp - this.start - actualTranslate * this.speed;
              this.procesosDiv.first.nativeElement.parentElement.style.transform =
                "translateX(-" +
                Math.min(step / this.speed, this.widthByProcess * this.active) +
                "px)";
              if (step < this.widthByProcess * this.active * this.speed) {
                this.req = window.requestAnimationFrame(animate);
              }
            };
            this.req = window.requestAnimationFrame(animate);
          }
        }
      }
    }
  }
  onMouseDown(e) {
    if (!e) {
      e = window.event;
    }
    if (this.procesosDiv.first.nativeElement.parentElement.contains(e.target)) {
      e.preventDefault();
      this.startx = e.clientX + this.el.scrollLeft;
      this.starty = e.pageY;
      this.xStart = e.clientX;
      this.yStart = e.clientY;
      this.diffx = 0;
      this.diffy = 0;
      this.mouseDown = true;
      this.xStarted = false;
      this.shouldScrollX = false;
      window.cancelAnimationFrame(this.req);
      this.procesosDiv.first.nativeElement.parentElement.style.transition =
        "none";
      this.renderer.addClass(this.el, "grab");
    } else {
      return;
    }
  }

  onTouchStart(e) {
    if (!e) {
      e = window.event;
    }
    if (this.procesosDiv.first.nativeElement.parentElement.contains(e.target)) {
      // e.preventDefault();
      this.startx = e.touches[0].clientX + this.el.scrollLeft;
      this.starty = e.touches[0].pageY;
      this.xStart = e.touches[0].clientX;
      this.yStart = e.touches[0].clientY;
      this.diffx = 0;
      this.diffy = 0;
      this.mouseDown = true;
      this.xStarted = false;
      this.shouldScrollX = false;
      window.cancelAnimationFrame(this.req);
      this.procesosDiv.first.nativeElement.parentElement.style.transition =
        "none";
      this.renderer.addClass(this.el, "grab");
    } else {
      return;
    }
  }

  changeProceso(id: number) {
    this.active = id;
    this.procesosDiv.first.nativeElement.parentElement.style.transform =
      "translateX(-" + this.widthByProcess * id + "px)";
    this.procesosDiv.first.nativeElement.parentElement.style.transition =
      "all 1s ease";
  }

  nextProceso() {
    this.active = this.active + 1;
    if (this.active > this.realProcesos.length) {
      this.active = this.realProcesos
        .map((proceso) => proceso.id)
        .sort((a, b) => {
          if (a > b) return 1;
          if (b > a) return -1;

          return 0;
        })[0];
    }
    this.procesosDiv.first.nativeElement.parentElement.style.transform =
      "translateX(-" + this.widthByProcess * this.active + "px)";
    this.procesosDiv.first.nativeElement.parentElement.style.transition =
      "all 1s ease";
  }
  backProceso() {
    this.active = this.active - 1;
    if (this.active <= 0) {
      this.active = this.realProcesos[this.realProcesos.length - 1].id;
    }
    this.procesosDiv.first.nativeElement.parentElement.style.transform =
      "translateX(-" + this.widthByProcess * this.active + "px)";
    this.procesosDiv.first.nativeElement.parentElement.style.transition =
      "all 1s ease";
  }
}
