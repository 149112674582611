import { Injectable } from "@angular/core";
import { HomeImage } from "../../models/home-image";
import { of, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  homeImages: HomeImage[] = [
    {
      id: 4,
      name: "Apto 1302",
      url: "../../assets/home/img4.jpg",
      description: "En María González Diseñamos Espacios Residenciales.",
    },
    {
      id: 2,
      name: "Consulado",
      url: "../../assets/home/img2.jpg",
      description: "En María González Diseñamos Espacios Comerciales.",
    },
    {
      id: 3,
      name: "Casa Messina",
      url: "../../assets/home/img3.jpg",
      description: "En María González Realizamos Renders 3D",
    },
    {
      id: 1,
      name: "Lean Tech",
      url: "../../assets/home/img1.jpg",
      description: "En María González Diseñamos Espacios de Oficina.",
    }
    // {
    //   id: 4,
    //   name: "image4",
    //   url: "../../assets/home/img4.jpg",
    //   description: "Laboris cupidatat aliqua cillum elit enim dolor amet esse.",
    // },
    // {
    //   id: 5,
    //   name: "image5",
    //   url: "../../assets/home/img5.jpg",
    //   description: "Laboris cupidatat aliqua cillum elit enim dolor amet esse.",
    // },
  ];

  constructor() {}

  getHomeImages(): Observable<HomeImage[]> {
    return of(this.homeImages);
  }
}
