import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ToolbarComponent } from "./toolbar/toolbar.component";
import { FooterComponent } from "./footer/footer.component";
import { RouterModule, Router } from "@angular/router";
import { PrivacyComponent } from "./privacy/privacy.component";
import { CookiesComponent } from "./cookies/cookies.component";
import {
  MatFormFieldModule,
  MatInputModule,
  MatDialogModule,
} from "@angular/material";
import { LandingComponentsModule } from "./landing-components/landing-components.module";
import { HttpClientModule } from "@angular/common/http";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { BrochureComponent } from "./downloads/brochure/brochure.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { SafePipe } from "./utils/safe-pipe";

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    FooterComponent,
    PrivacyComponent,
    CookiesComponent,
    BrochureComponent,
    SafePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    LandingComponentsModule,
    HttpClientModule,
    MatCheckboxModule,
    MatDialogModule,
    PdfViewerModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
