<div class="container">
  <div class="header">
    <div class="title">
      <span>Contáctanos</span>
    </div>
    <!-- <div class="description">
      <p>Proident ullamco quis cupidatat proident.</p>
    </div> -->
  </div>
  <div class="body">
    <ng-template [ngTemplateOutlet]="ContactInformation"></ng-template>
    <div class="form">
      <div *ngIf="!enviar">
        <!-- <div> -->
        <div class="contacto-title">
          Contácto
        </div>
        <form [formGroup]="contacto" class="cform-container">
          <mat-form-field class="input-container">
            <mat-label class="required">Nombre completo</mat-label>
            <input matInput formControlName="name" autocomplete="name" />
            <mat-error
              *ngIf="
                contacto.get('name').touched &&
                contacto.get('name').hasError('required') &&
                pressed
              "
              >Por favor ingresa tu nombre</mat-error
            >
          </mat-form-field>

          <mat-form-field class="input-container">
            <mat-label>Teléfono</mat-label>
            <input
              matInput
              autocomplete="tel"
              formControlName="phone"
              (keyup)="onKeyUp($event)"
              (focusout)="focusOut($event)"
            />
            <mat-error
              *ngIf="
                contacto.get('phone').touched &&
                contacto.get('phone').hasError('pattern') &&
                pressed
              "
              >Por favor ingresa un número válido</mat-error
            >
          </mat-form-field>

          <mat-form-field class="input-container">
            <mat-label class="required">Correo</mat-label>
            <input matInput autocomplete="email" formControlName="email" />
            <mat-error
              *ngIf="
                contacto.get('email').touched &&
                contacto.get('email').hasError('required') &&
                pressed
              "
              >Por favor ingresa tu e-mail</mat-error
            >
          </mat-form-field>

          <mat-form-field class="input-container">
            <mat-label>Mensaje</mat-label>
            <textarea matInput rows="5" formControlName="message"></textarea>
            <mat-error
              *ngIf="
                contacto.get('name').touched &&
                contacto.get('name').hasError('required') &&
                pressed
              "
              >Por favor redacta tu solicitud</mat-error
            >
          </mat-form-field>

          <div class="politicas">
            <mat-checkbox formControlName="privacy"
              >Acepto las
              <a href="/privacy" class="required" target="_blank"
                >políticas de privacidad</a
              ></mat-checkbox
            >
            <mat-error *ngIf="!contacto.get('privacy').value && pressed"
              >Por favor acepte las
              <a
                href="/privacy"
                style="text-decoration: none; margin-bottom: 0.5rem;"
                target="_blank"
                >políticas de privacidad</a
              ></mat-error
            >
            <mat-checkbox formControlName="newsletter"
              >Quiero suscribirme a su Newsletter</mat-checkbox
            >
          </div>
          <!-- <div
            class="g-recaptcha"
            data-sitekey="6Lfa9H0UAAAAAMAGt_pKuycKsKYFnIouFWeqInvd"
          ></div> -->
          <!-- <iframe
            src="https://cdn.forms-content.sg-form.com/c92ac425-a45a-11ea-ad73-c60d482796cf"
          ></iframe> -->
          <!-- <div
            id="captcha_element"
            class="g-recaptcha"
            [attr.data-sitekey]="siteKeyCaptcha"
            data-callback="gcaptchaResponse"
          ></div> -->
          <!-- start -->
          <div
            *ngIf="_name !== 'secondary'"
            class="g-recaptcha"
            #recaptcha
          ></div>
          <div
            *ngIf="_name === 'secondary'"
            class="g-recaptcha second"
            #recaptcha2
          ></div>
          <mat-error *ngIf="isRobot && pressed"
            >Por favor verifique si no es robot</mat-error
          >
          <div class="see-more-container">
            <div class="see-more" (click)="pressed = true; doSend()">
              <span>Enviar Mensaje</span>
            </div>
          </div>
        </form>
      </div>
      <div class="thanks" *ngIf="enviar" @Contactap @ContactfadeIn>
        <div class="thanks-header">
          Gracias por ponerte en contacto con nosotros!
        </div>
        <div class="thanks-body">
          Hemos recibido tu mensaje y estamos agradecidos por habernos escrito.
          Si tu solicitud es urgente, por favor llámanos al número de teléfono
          que aparece en esta sección para hablar con uno de nuestros agentes.
          De otra manera, responderemos su mensaje tan pronto como sea posible.
          <br /><br />
          Saludes, <br /><strong>María González</strong>
        </div>
        <!-- <ng-template [ngTemplateOutlet]="ContactInformation"></ng-template> -->
      </div>
    </div>
  </div>
</div>
<ng-template #ContactInformation
  ><div class="information">
    <div class="info information-location">
      <div class="info information-location-title">
        Ubicación
      </div>
      <div class="info information-location-description">
        Medellin, Antioquia<br />Colombia
      </div>
    </div>
    <div class="info information-more">
      <a
        href="mailto:{{ email }}"
        target="_blank"
        class="info information-email"
      >
        <i class="fas fa-envelope"></i>{{ email }}
      </a>
      <a
        href="tel://{{ phone }}"
        target="_blank"
        class="info information-cellphone"
      >
        <i class="fas fa-phone-alt"></i>{{ phone }}
      </a>
      <a
        target="_blank"
        href="{{ whatsapp }}"
        class="info information-whatsapp"
      >
        <i class="fab fa-whatsapp"></i>{{ phone }}
      </a>
      <!-- <a
    target="_blank"
    href="http://facebook.com/maria.gonzalez.estudio"
    class="info information-facebook"
    >
    <i class="fab fa-facebook-f"></i>María Gonzalez Estudio
    </a>
    <a
    target="_blank"
    href="https://www.instagram.com/mariagonzalez.estudio"
    class="info information-instagram"
    >
    <i class="fab fa-instagram"></i>María Gonzalez Estudio
    </a>
    <a
    target="_blank"
    href="https://www.behance.net/mariagoca9c360"
    class="info information-behance"
    >
    <i class="fab fa-behance"></i>María Gonzalez Estudio
    </a>
    <a
    target="_blank"
    href="https://www.linkedin.com/company/mariagonzalez/"
    class="info information-linkedin"
    >
    <i class="fab fa-linkedin-in"></i>María Gonzalez Estudio
    </a> -->
    </div>
  </div></ng-template
>
