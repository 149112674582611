<div #inicio>
  <app-home name="inicio"></app-home>
</div>
<div #servicios>
  <app-servicios name="servicios"></app-servicios>
</div>
<div #portafolio>
  <app-portafolio name="portafolio"></app-portafolio>
</div>
<div #nosotros>
  <app-nosotros name="nosotros"></app-nosotros>
</div>
<!-- <app-team></app-team> -->
<div #procesos>
  <app-procesos name="procesos"></app-procesos>
</div>
<div #contactenos>
  <app-contact name="contactenos"></app-contact>
</div>
<!-- <div class="toTop">
    <div>
      <i class="fas fa-angle-up"></i>
    </div>
  </div> -->
<span class="caret" (click)="goTop()"
  ><div class="caret-animation">
    <i class="fas fa-caret-up"></i></div
></span>
