import { Component, OnInit } from "@angular/core";
import { ServiciosService } from "src/app/services/servicios.service";
import { Servicios } from "src/models/servicios";

@Component({
  selector: "app-servicios",
  templateUrl: "./servicios.component.html",
  styleUrls: ["./servicios.component.scss"],
})
export class ServiciosComponent implements OnInit {
  servicios: Servicios[];
  constructor(private serviciosService: ServiciosService) {}

  ngOnInit() {
    this.getServicios();
  }

  getServicios() {
    this.serviciosService
      .getServicios()
      .subscribe((servicios) => (this.servicios = servicios));
  }
}
