import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { throwError, Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment as env } from "../../environments/environment";
import { Country } from "src/models/country";
import { Gender } from "src/models/gender";
@Injectable({
  providedIn: "root",
})
export class ContactService {
  httpOptions: IRequestOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }),
    observe: "response",
    responseType: "text",
  };
  ownUrl = env.ownUrl;
  subscribeUrl = env.subscribeUrl;
  constructor(private httpClient: HttpClient) {}

  sendInformation(contacto): Observable<any> {
    // return;
    return this.httpClient
      .post(this.ownUrl, JSON.stringify(contacto.value), this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }
  //todo: implement api to put a new contact
  subscribe(contacto, grecaptcha): Observable<any> {
    const fullName = contacto.get("name").value;
    const arrays = fullName.replace(/\s+/g, " ").trim().split(" ");
    let first;
    let last = "";
    switch (arrays.length) {
      case 1:
        first = arrays[0];
        break;
      case 2:
        first = arrays[0];
        last = arrays[1];
      default:
        first = arrays.slice(0, -1).join(" ");
        last = arrays.slice(-1).join("");
    }
    let body = JSON.stringify({
      first_name: first && first.trim() ? first.trim() : undefined,
      last_name: last && last.trim() ? last.trim() : undefined,
      email: contacto.get("email").value.trim(),
      recaptcha: grecaptcha,
      phone_number:
        contacto.get("phone").value && contacto.get("phone").value.trim()
          ? contacto.get("phone").value.trim()
          : undefined,
      gender:
        contacto.get("gender").value && contacto.get("gender").value.trim()
          ? contacto.get("gender").value.trim()
          : undefined,
      city:
        contacto.get("city").value && contacto.get("city").value.trim()
          ? contacto.get("city").value.trim()
          : undefined,
      year:
        contacto.get("year").value && contacto.get("year").value.trim()
          ? contacto.get("year").value.trim()
          : undefined,
      country:
        contacto.get("country").value && contacto.get("country").value.trim()
          ? contacto.get("country").value.trim()
          : undefined,
    });
    // return;
    return this.httpClient
      .post(this.subscribeUrl, body, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
        responseType: "json",
      })
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // console.log(errorMessage);
    return throwError(errorMessage);
  }
  getCountries(): Observable<Country[]> {
    return this.httpClient
      .get<Country[]>("../../assets/shared/countries.json")
      .pipe(catchError(this.errorHandler));
  }
  getGenders(): Observable<Gender[]> {
    return this.httpClient
      .get<Gender[]>("../../assets/shared/genders.json")
      .pipe(catchError(this.errorHandler));
  }
}
export interface IRequestOptions {
  body?: any;
  headers?: HttpHeaders | { [header: string]: string | Array<string> };
  observe?: any;
  params?: HttpParams | { [param: string]: string | Array<string> };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
}
