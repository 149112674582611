import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject } from "rxjs";
import { filter, pairwise } from "rxjs/operators";
import { Item } from "../../models/item";
import {
  Event,
  NavigationEnd,
  Router,
  RoutesRecognized,
} from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  public urlState = new BehaviorSubject<string>(undefined);
  menuItems: Item[] = [
    {
      id: 1,
      name: "Inicio",
      path: "inicio",
      active: true,
      disable: false,
    },
    {
      id: 2,
      name: "Servicios",
      path: "servicios",
      active: false,
      disable: false,
    },
    {
      id: 3,
      name: "Portafolio",
      path: "portafolio",
      active: false,
      disable: false,
    },
    {
      id: 4,
      name: "Nosotros",
      path: "nosotros",
      active: false,
      disable: false,
    },
    {
      id: 5,
      name: "Equipo",
      path: "equipo",
      active: false,
      disable: true,
    },
    {
      id: 6,
      name: "¿Cómo trabajamos?",
      path: "procesos",
      active: false,
      disable: false,
    },
    {
      id: 7,
      name: "Contacto",
      path: "contactenos",
      active: false,
      disable: false,
    },
  ];
  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.urlState.next(event.urlAfterRedirects);
      }
    });
  }
  getMenuItems(): Observable<Item[]> {
    return of(this.menuItems.filter((item) => !item.disable));
  }
}
