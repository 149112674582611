import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { LandingComponentsComponent } from "./landing-components.component";
import { ServiciosComponent } from "./servicios/servicios.component";
import { PortafolioComponent } from "./portafolio/portafolio.component";
import { NosotrosComponent } from "./nosotros/nosotros.component";
import { TeamComponent } from "./team/team.component";
import { ContactComponent } from "./contact/contact.component";
import { ProyectoComponent } from "../proyecto/proyecto.component";

const routes: Routes = [
  { path: "", component: LandingComponentsComponent },
  // { path: "inicio", component: LandingComponentsComponent },
  // { path: "servicios", component: ServiciosComponent },
  // { path: "portafolio", component: PortafolioComponent },
  // { path: "nosotros", component: NosotrosComponent },
  // { path: "equipo", component: TeamComponent },
  // { path: "contactenos", component: ContactComponent },
  // { path: ":id", component: ProyectoComponent },
  { path: "**", component: LandingComponentsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandingComponentsRoutingModule {}
