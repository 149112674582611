import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { LandingComponentsRoutingModule } from "./landing-components-routing.module";
import { HomeComponent } from "./home/home.component";
import { PortafolioComponent } from "./portafolio/portafolio.component";
import { NosotrosComponent } from "./nosotros/nosotros.component";
import { TeamComponent } from "./team/team.component";
import {
  ContactComponent,
  ConfirmDialog,
  MoreInfoDialog,
} from "./contact/contact.component";
import { ServiciosComponent } from "./servicios/servicios.component";
import { LandingComponentsComponent } from "./landing-components.component";
import { MatFormFieldModule, MatInputModule } from "@angular/material";
import { MatButtonModule } from "@angular/material/button";
import { ProcesosComponent } from "./procesos/procesos.component";
import { ProyectoComponent } from "../proyecto/proyecto.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
  declarations: [
    HomeComponent,
    PortafolioComponent,
    NosotrosComponent,
    TeamComponent,
    ContactComponent,
    ServiciosComponent,
    LandingComponentsComponent,
    ProcesosComponent,
    ProyectoComponent,
    ConfirmDialog,
    MoreInfoDialog,
  ],
  imports: [
    CommonModule,
    LandingComponentsRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSelectModule,
  ],
  exports: [ContactComponent],
  entryComponents: [ConfirmDialog, MoreInfoDialog],
})
export class LandingComponentsModule {}
