export const environment = {
  production: true,
  ownUrl: "./contact_process.php",
  subscribeUrl: "./subscribe.php",
  email: "info@mariagonzalez.co",
  phone: "+57 3024594585",
  whatsapp: "https://api.whatsapp.com/send?phone=573024594585",
  clientKey: "6LeUQf8UAAAAAFu4SbHNgwzJ-xHrViMvvl3wPNZT",
  clientKey2: "6LcRY6YZAAAAANL0CWZdMOSKCW0HeJeWpe9bus7K",
  brochureUrl: "/assets/shared/brochure.pdf",
};
