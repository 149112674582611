<div class="m-dialog">
  <div class="top">
    <i class="far fa-check-circle"></i>
  </div>
  <div class="header">Hola, <strong>{{data.name}}</strong></div>
  <div class="body">
    Para una Newsletter más personal, agradeceríamos que completaras los
    siguientes datos (Opcional)
  </div>
  <div mat-dialog-content>
    <mat-form-field class="input-container">
      <mat-label>País</mat-label>
      <mat-select [(ngModel)]="data.country">
        <div class="m-dialog-more-info-buscar">
          <i class="fas fa-search"></i>
          <input
            matInput
            autocomplete="off"
            (keyup)="onKeyup($event)"
            (keydown)="$event.stopPropagation()"
            placeholder="Colombia"
          />
        </div>
        <mat-option
          autocomplete="country"
          *ngFor="let country of selectedCountries"
          [value]="country.name"
        >
          {{country.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="input-container">
      <mat-label>Género</mat-label>
      <mat-select [(ngModel)]="data.gender">
        <mat-option
          autocomplete="sex"
          *ngFor="let gender of genders"
          [value]="gender.name"
        >
          {{gender.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="input-container">
      <mat-label>Año de nacimiento</mat-label>
      <input matInput [(ngModel)]="data.year" autocomplete="bday-year" />
    </mat-form-field>

    <mat-form-field class="input-container" *ngIf="data.country==='Colombia'">
      <mat-label>Ciudad</mat-label>
      <input matInput [(ngModel)]="data.city" autocomplete="address-level1" />
    </mat-form-field>
  </div>
  <div class="see-more-container">
    <div class="see-more" [mat-dialog-close]="data" cdkFocusInitial>
      <span>Enviar</span>
    </div>
  </div>
  <div class="see-more-container">
    <div class="see-more" (click)="goOut()">
      <span>No, gracias</span>
    </div>
  </div>
</div>
