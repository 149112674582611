import { Injectable } from "@angular/core";
import { Portafolio } from "../../models/portafolio";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PortafolioService {
  projects: Portafolio[] = [
    {
      id: 6,
      name: "Casa Messina",
      category: "Arquitectura e Interiorismo",
      imageUrl: "../../../assets/portafolio/casa messina/img1.jpg",
      shortDescription:
        "Casa Messina, es una cabaña cruda, honesta y simple, ubicada entre las rocas a la orilla del mar. Su misión se centra en abrazar el impresionante paisaje y utilizar métodos experimentales de diseño pasivo.",
      description:
        "Esta casa tiene un enfoque puramente funcional y estético en cuanto al uso del espacio, la privacidad y la conexión visual. Cuenta tan solo con la habitación, una pequeña cocina y un baño con total conexión que ofrece sol, luz, ventilación, felicidad, actividades relajantes, estimulación visual y el entretenimiento.<br> Esta conexión actúa como el nodo central de la experiencia de un paseo marítimo.",
      area: 50,
      year: 2020,
      location: "Messina - Italia",
      repository: "./assets/portafolio/casa messina/",
    },
    {
      id: 2,
      name: "Bio",
      category: "Interiorismo",
      imageUrl: "../../../assets/portafolio/bio/img1.jpg",
      shortDescription:
        "Bio, es un proyecto que le apuesta a las nuevas tendencias en pro de proteger el medio ambiente.",
      description:
        '"BIO" este proyecto comienza con una investigación previa del  Biophilic design, tomando como punto de enfoque su concepto y patrones, transformándolos en un espacio con identidad, que conecta y estimula todos los sentidos, logrando de tal manera la reducción del estrés, desconexión del mundo laboral, armonía y calor de hogar.<br> En este proceso priman las formas fractales, conexiones visuales que hacen sentir frescura y equilibrio con el interior, los materiales escogidos en su mayoría son  vernáculos y los que complementan son análogos naturales.',
      area: 28.7,
      year: 2020,
      location: "Medellín - Antioquia",
      repository: "./assets/portafolio/bio/",
    },
    {
      id: 3,
      name: "Apartamento 1301",
      category: "Interiorismo",
      imageUrl: "../../../assets/portafolio/apto 1/img1.jpg",
      shortDescription:
        "Para este proyecto ubicado en los Balsos, una zona alta de Medellín, con una de las mejores vistas , se pensó en la combinación de materiales y contrastes, prevaleciendo texturas naturales, luces cálidas, dándole protagonismo al paisaje que por ahí se puede observar.",
      description:
        "En este proyecto, existe una verdadera conexión con la naturaleza, respetando el ambiente y con un enfoque sofisticado. Por medio de los materiales, colores y luces se puede lograr un ambiente acogedor.<br> La diseñadora de nuestro estudio, reviso detalladamente el diseño inicial del apartamento, sin intervenir la estructura, logro dar una visión más amplia del espacio por medio del mobiliario además que en cada rincón se invita a echar un vistazo a la naturaleza.",
      area: 300,
      year: 2020,
      location: "Los Balsos, Medellín - Antioquia",
      repository: "./assets/portafolio/apto 1/",
    },
    {
      id: 4,
      name: "Apartamento 1302",
      category: "Interiorismo",
      imageUrl: "../../../assets/portafolio/apto 2/img1.jpg",
      shortDescription:
        "El apartamento diseñado cuenta con un área muy amplia, está pensado para un hombre con un estilo de vida activo y buen gusto para el arte.",
      description:
        "Este apartamento ubicado en los balsos, Medellín, fue adquirido por nuestro cliente, un hombre estadounidense que se interesa por el arte, de buenas relaciones y con mucho estilo.<br> Por ello, ya teniendo una idea del tipo de interior que desea, se propone usar monocromo en color, minimalista en contenido y masculino en carácter.<br> Se propuso tonos grises, cafés oscuros y negro, el gres porcelaníco en gran formato, algunos con betas blancas, que permiten entre esos colores refrescar el ambiente, en la sala de tv se propuso un panel de madera oscura en el techo con unos spots para crear una atmosfera acogedora y adecuada.",
      area: 300,
      year: 2020,
      location: "Los Balsos, Medellín - Antioquia",
      repository: "./assets/portafolio/apto 2/",
    },
    {
      id: 5,
      name: "Consulado Honorario de Italia en Barranquilla",
      category: "Interiorismo",
      imageUrl: "../../../assets/portafolio/consulado/img1.jpg",
      shortDescription:
        "El concepto del Consulado Honorario de Italia en Barranquilla, comienza con el objetivo de desarrollar un lugar que permita hacer los tramites legales para ingresar a Italia y al mismo tiempo, un espacio que brinda clases de Italiano.",
      description:
        "Este nuevo espacio es una reinvención de sus anteriores oficinas, para el cual se tomaron materiales y elementos ya existentes dándoles un nuevo uso y distribución. El concepto propuesto aprovecha al máximo cada área dándole uno o más usos, los muros son paneles corredizos y desmontables que permiten conectar dos aulas, estos están enchapados en formica tablero en los cuales se plasman las clases.",
      area: 100,
      year: 2019,
      location: "Barranquilla - Atlántico",
      repository: "./assets/portafolio/consulado/",
    },
    {
      id: 1,
      name: "Lean Tech",
      category: "Interiorismo",
      imageUrl: "../../../assets/portafolio/lean tech/img1.jpg",
      shortDescription:
        "Las nuevas oficinas de Lean Tech fueron diseñadas a partir de una estrategia de trabajo en equipo. Se utilizaron los principales referentes de la compañía en la conceptualización del espacio, identificando necesidades y expectativas que dieron forma a los requerimientos del proyecto.",
      description:
        "Cada espacio está concebido para promover las fortalezas del trabajo en equipo, establecer una relación de trabajo y un vínculo a largo plazo, poniendo además un foco en el desarrollo individual. De esta manera, se hace evidente a simple vista el aprovechamiento de los espacios, con gran capacidad de adaptación, respondiendo a necesidades variables de cada equipo y atendiendo el potencial de crecimiento de la compañía. Dentro de factores claves que están presente en este proyecto, se evidencia la adaptación, fácil movilidad e interacción. También estuvo presente el concepto de hogar, al estar casi un 70% de sus trabajadores en otra ciudad, este concepto fue muy necesario a la hora de desarrollar la propuesta, el cual nos llevó a proyectar un espacio en donde se sientan como en casa, generando experiencias vivenciales que los invita siempre a querer volver.",
      area: 850,
      year: 2019,
      location: "Medellín - Antioquia",
      repository: "./assets/portafolio/lean tech/",
    },
  ];

  constructor() {}

  getProjects(): Observable<Portafolio[]> {
    return of(this.projects);
  }
  getProjectByID(id: number): Observable<Portafolio> {
    if (this.projects[id]) {
      return of(this.projects[id]);
    } else {
      return of(null);
    }
  }
  getProjectByName(name: string): Observable<Portafolio> {
    return of(
      this.projects.filter(
        (project) => project.name.replace("-", " ").toLowerCase() === name
      )[0]
    );
  }
}
