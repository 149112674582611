import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment as env } from "../../environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DownloadsService {
  brochureUrl = env.brochureUrl;
  constructor(private httpClient: HttpClient) {}

  downloadBrochure(): Observable<any> {
    return this.httpClient.get(this.brochureUrl, {
      headers: {
        "Content-Type": "application/pdf",
        "Content-Disposition": "inline",
      },
      responseType: "blob",
    });
  }
}
