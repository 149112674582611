<div class="m-dialog">
  <div class="top">
    <i class="far fa-check-circle"></i>
  </div>
  <div class="header">Hola, <strong>{{data.name}}</strong></div>
  <div class="body confirm">
    Vimos que te habías registrado anteriormente en nuestro newsletter. Por eso,
    hemos actualizado tus datos.
  </div>
  <div class="see-more-container">
    <div class="see-more" (click)="goOut()">
      <span>OK</span>
    </div>
  </div>
</div>
