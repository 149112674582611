<div
  class="shadow"
  *ngIf="responsive"
  (click)="responsive = !responsive"
  [@fadeInOut2]
  #shadow
></div>
<div
  class="navbar-background"
  #navbar
  [ngClass]="{ 'padding-toolbar': !responsive }"
>
  <div class="elements">
    <div class="logo">
      <div class="logo-img">
        <a [routerLink]="['/']">
          <img
            class="image"
            src="../../assets/shared/logo-white.png"
            alt="María González"
          />
        </a>
      </div>
    </div>
    <div class="sub-elements">
      <span
        *ngFor="let item of menuItems"
        class="text-sub-elements"
        [ngClass]="{ 'active-white': item.active }"
        [routerLink]="'.'"
        [fragment]="item.path"
      >
        {{ item.name | uppercase }}
      </span>
    </div>
    <div
      class="sub-elements responsive"
      (click)="responsive = !responsive; checkShadow()"
    >
      <span class="hamburguer-menu">
        <i class="fas fa-bars"></i>
      </span>
    </div>
  </div>
  <div class="menu-responsive" *ngIf="responsive" [@inOutAnimation]>
    <div class="menu-responsive-background">
      <div class="sub-elements-responsive">
        <span
          *ngFor="let item of menuItems"
          (click)="responsive = !responsive"
          class="text-sub-elements"
          [ngClass]="{ active: item.active }"
          [routerLink]="'.'"
          [fragment]="item.path"
        >
          <span>{{ item.name }}</span>
        </span>
      </div>
    </div>
  </div>
  <!-- <div class="shape hide" #shape>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      viewBox="0 0 1440 100"
    >
      <path
        fill="#a5a39e"
        shape-rendering="optimizeQuality"
        fill-opacity="1"
        d="M 0 100 L 400 100 C 800 100 1100 100 1450 0 L 0 0 L 0 100 "
      />
    </svg>
  </div> -->
</div>

<!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path fill="#0099ff" fill-opacity="1" d="M0,224L120,229.3C240,235,480,245,720,218.7C960,192,1200,128,1320,96L1440,64L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path>
</svg> -->
