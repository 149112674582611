<div class="main" *ngIf="project">
  <div class="full-image-container">
    <img class="full-image" src="{{ project.imageUrl }}" />
    <div class="brief">
      <div class="brief-border">
        <div class="brief-main-container">
          <div class="brief-container">
            <div class="project-name">
              {{ project.name }}
            </div>
            <div class="project-location">
              {{ project.location }}
            </div>
          </div>
          <div class="project-date">
            <!-- <img
      class="year-slash"
      src="../../assets/shared/year.png"
      alt="Separador"
      /> -->
            {{ project.year }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="project-description-container">
    <div class="contactanos-desktop">
      <app-contact [name]="'secondary'"></app-contact>
    </div>
    <div class="project-description">
      <div class="title">
        <span>
          Detalles
        </span>
      </div>
      <div class="short-description">
        <p>Localización: {{ project.location }}</p>
        <p>Área: {{ project.area }} m²</p>
        <p>
          {{ project.shortDescription }}
        </p>
      </div>
      <div
        class="full-description"
        [ngClass]="{ show: expandir, 'max-height-0': !expandir }"
      >
        <p #description>
          {{ project.description }}
        </p>
      </div>
      <div class="saber-mas" [ngClass]="{ showed: !expandir, hide: expandir }">
        <a (click)="getExpand()"
          >Saber más <i class="fas fa-caret-right caret-m-left"></i
        ></a>
      </div>
      <div class="saber-mas" [ngClass]="{ hide: !expandir, showed: expandir }">
        <a (click)="getExpand()"
          >Ocultar <i class="fas fa-caret-right caret-m-left"></i
        ></a>
      </div>
    </div>
  </div>
  <div class="project-images">
    <div
      class="image-container fadeInUp"
      *ngFor="let image of images; index as idx"
      #images
    >
      <div class="image-subcontainer">
        <img
          [src]="image"
          class="image"
          (error)="outOfLimit(idx + 1)"
          (load)="successfulLoad(idx + 1)"
          alt=""
        />
      </div>
    </div>
  </div>
</div>
<br />
<app-contact></app-contact>
