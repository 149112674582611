import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { Subscriber } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "maria-gonzalez";
  router: string;
  routerEvents: any;
  constructor(private _router: Router) {
    this.router = _router.url;
  }

  initRouteSubscribers() {
    this.routerEvents = this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router = event.url;
      }
    });
  }

  ngOnInit() {
    this.initRouteSubscribers();
    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#a5a39e",
          text: "rgba(33, 37, 41, 0.7)",
        },
        button: {
          background: "#FFFFFF",
          text: "rgba(33, 37, 41, 0.7)",
        },
      },
      theme: "classic",
      content: {
        message:
          "Usamos cookies para mejorar tu experiencia. Al continuar visitando este sitio tú estás aceptando nuestra política de cookies.",
        dismiss: "Entendido!",
        link: "Saber más",
        href: "/#/cookies",
      },
    });
  }
  ngOnDestroy(): void {
    this.routerEvents.dispose();
  }
}
