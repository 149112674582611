import { Injectable } from "@angular/core";
import { Servicios } from "../../models/servicios";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ServiciosService {
  servicios: Servicios[] = [
    {
      id: 2,
      title: "Asesoría",
      description:
        "Ullamco elit veniam do duis consequat ad aliqua tempor adipisicing aute exercitation do sint.",
      iconUrl: "../../assets/services/ASESORIA.png",
    },
    {
      id: 4,
      title: "Mobiliario",
      description:
        "Sint voluptate occaecat aliqua quis et proident tempor irure veniam voluptate id.",
      iconUrl: "../../assets/services/MOBILIARIO.png",
    },
    {
      id: 3,
      title: "Digitalización 3D",
      description:
        "Duis consequat exercitation ullamco elit nostrud laboris cillum voluptate amet.",
      iconUrl: "../../assets/services/DIGITALIZACION.png",
    },
    {
      id: 1,
      title: "Diseño Interior",
      description: "Enim ea commodo in ex ea amet laboris commodo nulla qui.",
      iconUrl: "../../assets/services/DISEÑO.png",
    },
  ];

  constructor() {}

  getServicios(): Observable<Servicios[]> {
    return of(this.servicios.sort((a, b) => a.id - b.id));
  }
}
