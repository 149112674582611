// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ownUrl: "http://dev.mariagonzalez.co/contact_process.php",
  subscribeUrl: "http://dev.mariagonzalez.co/subscribe.php",
  email: "info@mariagonzalez.co",
  phone: "+57 3024594585",
  whatsapp: "https://api.whatsapp.com/send?phone=573024594585",
  clientKey: "6LeUQf8UAAAAAFu4SbHNgwzJ-xHrViMvvl3wPNZT",
  clientKey2: "6LcRY6YZAAAAANL0CWZdMOSKCW0HeJeWpe9bus7K",
  brochureUrl: "/assets/shared/brochure.pdf",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
