import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { environment as env } from "../../../environments/environment.prod";
import { ElementRef } from "@angular/core";
import { Observable } from "rxjs";
import { DownloadsService } from "../../services/downloads.service";
import { saveAs } from "file-saver";
@Component({
  selector: "app-brochure",
  templateUrl: "./brochure.component.html",
  styleUrls: ["./brochure.component.scss"],
})
export class BrochureComponent implements OnInit, AfterViewInit {
  @ViewChild("pdfViewer") pdfContainer: ElementRef;
  isMobile = false;
  constructor(private downloadsService: DownloadsService) {}

  pdfSrc = env.brochureUrl;
  pdfUrl = "";
  ngOnInit() {}
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.pdfContainer.nativeElement.clientWidth < 500) {
        this.isMobile = true;
        this.download();
      }
    }, 0);
  }
  download() {
    this.downloadsService.downloadBrochure().subscribe((response) => {
      let blob: any = new Blob([response], {
        type: "application/pdf",
      });
      this.pdfUrl = window.URL.createObjectURL(blob);
      // location.href = url;
      // window.open(this.pdfUrl, "_top");
      // window.location.href = response.url;
      saveAs.saveAs(blob, "Brochure.pdf");
    }),
      (error) => console.log("Error downloading the file"),
      () => console.info("File downloaded successfully");
  }
}
