<div class="container">
  <div class="top">
    <div class="top-container">
      <div class="privacy">
        <div class="privacy-content">
          <span>
            <a href="/privacy" target="_blank">
              {{ "Política de privacidad" | uppercase }}
            </a>
          </span>
          <span>
            <a href="/cookies" target="_blank">
              {{ "Términos y condiciones" | uppercase }}
            </a>
          </span>
          <span>
            <div [routerLink]="'.'" [fragment]="'nosotros'">
              {{ "Acerca de" | uppercase }}
            </div>
          </span>
        </div>
      </div>
      <div class="services">
        <div class="services-content">
          <span>
            <div [routerLink]="'.'" [fragment]="'servicios'">
              {{ "Servicios" | uppercase }}
            </div>
          </span>
          <span>
            <div [routerLink]="'.'" [fragment]="'portafolio'">
              {{ "Portafolio" | uppercase }}
            </div>
          </span>
          <span>
            <div [routerLink]="'.'" [fragment]="'contactenos'">
              {{ "Contácto" | uppercase }}
            </div>
          </span>
        </div>
      </div>
      <div class="float center">
        <div class="logo">
          <a [routerLink]="['/']">
            <img
              class="logo-img"
              src="../../assets/shared/solo-logo.png"
              alt="María González"
            />
          </a>
        </div>
        <div class="social-icons">
          <div class="social-icons-container">
            <a
              href="https://www.instagram.com/mariagonzalez.estudio"
              target="_blank"
            >
              <i class="fab fa-instagram-square social-img"></i>
            </a>
            <a href="https://www.behance.net/mariagoca9c360" target="_blank">
              <i class="fab fa-behance-square social-img"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/mariagonzalez/"
              target="_blank"
            >
              <i class="fab fa-linkedin social-img"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom">
    <div class="title">
      <span> SERVICIOS </span>
    </div>
    <div class="services-responsive">
      <a
        [routerLink]="'.'"
        [fragment]="'contactenos'"
        *ngFor="let servicio of servicios"
      >
        {{ servicio.title }}
      </a>
    </div>
    <div class="about">
      <a [routerLink]="'.'" [fragment]="'nosotros'"> NOSOTROS </a>
    </div>
  </div>
</div>
<hr class="hr-white opacity-10" />
<div class="copyright">
  <span> © 2021 </span>
</div>
