<div class="container" draggable="false">
  <div class="header-container">
    <div class="header">
      <span>{{ "Proceso de trabajo" }}</span>
    </div>
  </div>
  <div class="procesos-main-container">
    <div
      class="procesos-container"
      #container
      (mousedown)="onMouseDown($event)"
      (touchstart)="onTouchStart($event)"
    >
      <div
        id="{{ proceso.id }}"
        class="procesos"
        *ngFor="let proceso of procesos"
        #procesosDiv
      >
        <div class="frame">
          <div class="body-container">
            <div class="body">
              <div class="left" (click)="backProceso()">
                <div class="left-header">
                  <span>
                    {{ proceso.id - 1 > 0 ? proceso.id - 1 : procesos.length }}
                  </span>
                  <span>
                    {{
                      procesos[
                        proceso.id - 1 > 0
                          ? proceso.id - 2
                          : procesos.length - 1
                      ].name
                    }}
                  </span>
                </div>
                <div class="left-button">
                  <img
                    src="../../../assets/shared/left-arrow.png"
                    alt="Atrás"
                  />
                </div>
              </div>
              <div class="center">
                <div class="title">
                  <span>
                    {{ proceso.name }}
                  </span>
                </div>
                <div class="number-background">
                  {{ proceso.id | number: "2.0" }}
                </div>
                <div class="description">
                  <p>
                    {{ proceso.description }}
                  </p>
                </div>
              </div>
              <div class="right" (click)="nextProceso()">
                <div class="right-header">
                  <span>
                    {{ proceso.id == procesos.length ? "1" : proceso.id + 1 }}
                  </span>
                  <span>
                    {{
                      procesos[
                        proceso.id == procesos.length ? 0 : proceso.id - 1
                      ].name
                    }}
                  </span>
                </div>
                <div class="right-button">
                  <img
                    src="../../../assets/shared/right-arrow.png"
                    alt="Siguiente"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="image-main-container">
          <div class="image-container">
            <img
              class="image"
              src="{{ proceso.imageUrl }}"
              alt="{{ proceso.name }}"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dots">
    <div
      class="dot"
      id="{{ proceso.id }}"
      [ngClass]="{ active: proceso.id == active }"
      *ngFor="let proceso of realProcesos"
      (click)="changeProceso(proceso.id)"
    >
      <span></span>
    </div>
  </div>
</div>
