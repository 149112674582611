import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
  HostListener,
} from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { MenuService } from "../services/menu.service";
import { Item } from "../../models/item";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "app-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  animations: [
    trigger("fadeInOut", [
      state(
        "void",
        style({
          opacity: 0,
        })
      ),
      transition("void <=> *", animate(500)),
    ]),
    trigger("fadeInOut2", [
      state(
        "void",
        style({
          opacity: 0,
        })
      ),
      transition("void <=> *", animate(200)),
    ]),
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ height: 0 }),
        animate("0.3s ease-out", style({ height: 213 })),
      ]),
      transition(":leave", [
        style({ height: 213 }),
        animate("0.3s ease-in", style({ height: 0 })),
      ]),
    ]),
  ],
})
export class ToolbarComponent implements OnInit, AfterViewInit {
  @ViewChild("navbar") navBar: ElementRef;
  @ViewChild("shadow") shadow: ElementRef;
  @ViewChild("shape") shape: ElementRef;
  routeSubscription: Subscription;
  previousUrl: string;
  currentUrl: string;
  menuItems: Item[];
  navBarHeight = 0;
  responsive = false;
  shouldIWork = true;
  allScrolls = [];

  constructor(
    private menuService: MenuService,
    private renderer: Renderer2,
    private router: Router
  ) {}

  ngOnInit() {
    window.addEventListener("scroll", this.scrollEvent, true);
    this.getMenuItems();
    this.menuService.urlState.subscribe((url: string) => {
      if (url) {
        this.scrollEvent(null);
        this.menuItems.map((item) => {
          if (item.path === url.replace("#", "").replace("/", "")) {
            item.active = true;
          } else {
            item.active = false;
          }
        });
      }
    });
  }

  ngAfterViewInit() {
    this.checkNavBarHeigth();
  }

  checkNavBarHeigth() {
    setTimeout(() => {
      this.navBarHeight = parseInt(
        this.navBar.nativeElement.ownerDocument.defaultView
          .getComputedStyle(this.navBar.nativeElement, null)
          .getPropertyValue("height"),
        10
      );
    }, 0);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    setTimeout(() => {
      if (window.innerWidth > 1024) {
        this.responsive = false;
      }
    }, 0);
  }

  getMenuItems() {
    this.menuService
      .getMenuItems()
      .subscribe((items) => (this.menuItems = items));
  }

  checkShadow() {
    setTimeout(() => {
      if (window.scrollY > this.navBarHeight / 2) {
        if (this.shadow) {
          this.renderer.removeClass(this.shadow.nativeElement, "clarify");
        }
      } else {
        if (this.shadow) {
          this.renderer.addClass(this.shadow.nativeElement, "clarify");
        }
      }
    }, 0);
  }

  scrollEvent = (event: any): void => {
    if (this.router.url === "/privacy" || this.router.url === "/cookies") {
      this.renderer.addClass(this.navBar.nativeElement, "nb-gray");
      this.renderer.removeClass(this.navBar.nativeElement, "nb-gray-hide");
      if (event) {
        if (event.srcElement.scrollingElement) {
          if (
            event.srcElement.scrollingElement.scrollTop >
            this.navBarHeight / 2
          ) {
            this.renderer.removeClass(
              this.navBar.nativeElement,
              "nb-gray-hide"
            );
            // this.renderer.addClass(this.shape.nativeElement, "show");
            if (this.shadow) {
              this.renderer.removeClass(this.shadow.nativeElement, "clarify");
            }
          } else {
            // this.renderer.removeClass(this.navBar.nativeElement, "nb-gray");
            // this.renderer.removeClass(this.shape.nativeElement, "show");
            if (this.shadow) {
              this.renderer.addClass(this.shadow.nativeElement, "clarify");
            }
          }
        } else if (event.srcElement.documentElement) {
          if (
            event.srcElement.documentElement.scrollTop >
            this.navBarHeight / 2
          ) {
            this.renderer.removeClass(
              this.navBar.nativeElement,
              "nb-gray-hide"
            );
            // this.renderer.addClass(this.shape.nativeElement, "show");
            if (this.shadow) {
              this.renderer.removeClass(this.shadow.nativeElement, "clarify");
            }
          } else {
            // this.renderer.removeClass(this.navBar.nativeElement, "nb-gray");
            // this.renderer.removeClass(this.shape.nativeElement, "show");
            if (this.shadow) {
              this.renderer.addClass(this.shadow.nativeElement, "clarify");
            }
          }
        }
      }
    } else {
      if (event) {
        if (event.target.nodeName === "#document") {
          if (event.srcElement.scrollingElement) {
            if (
              event.srcElement.scrollingElement.scrollTop >
              this.navBarHeight / 2
            ) {
              this.renderer.addClass(this.navBar.nativeElement, "nb-gray");
              this.renderer.removeClass(
                this.navBar.nativeElement,
                "nb-gray-hide"
              );
              // this.renderer.addClass(this.shape.nativeElement, "show");
              if (this.shadow) {
                this.renderer.removeClass(this.shadow.nativeElement, "clarify");
              }
            } else {
              // this.renderer.removeClass(this.navBar.nativeElement, "nb-gray");
              this.renderer.addClass(this.navBar.nativeElement, "nb-gray-hide");
              // this.renderer.removeClass(this.shape.nativeElement, "show");
              if (this.shadow) {
                this.renderer.addClass(this.shadow.nativeElement, "clarify");
              }
            }
          } else if (event.srcElement.documentElement) {
            if (
              event.srcElement.documentElement.scrollTop >
              this.navBarHeight / 2
            ) {
              this.renderer.addClass(this.navBar.nativeElement, "nb-gray");
              this.renderer.removeClass(
                this.navBar.nativeElement,
                "nb-gray-hide"
              );
              // this.renderer.addClass(this.shape.nativeElement, "show");
              if (this.shadow) {
                this.renderer.removeClass(this.shadow.nativeElement, "clarify");
              }
            } else {
              // this.renderer.removeClass(this.navBar.nativeElement, "nb-gray");
              this.renderer.addClass(this.navBar.nativeElement, "nb-gray-hide");
              // this.renderer.removeClass(this.shape.nativeElement, "show");
              if (this.shadow) {
                this.renderer.addClass(this.shadow.nativeElement, "clarify");
              }
            }
          }
        }
      } else {
        if (window.pageYOffset === 0) {
          this.renderer.addClass(this.navBar.nativeElement, "nb-gray-hide");
        }
      }
    }
  };
}
