<div class="container content_grid_ayuda">
  <div class="conocenos__cont">
    <div class="titulo-cookies">
      <h1 class="titular" id="p140534-m67-66-68">
        POLÍTICA DE DATOS DE NAVEGACIÓN<br /><br />
      </h1>
    </div>
    <div class="conocenos__cont__content">
      <p class="contenido" id="p140534-m62-61-63" style="width: auto;">
        <b>1. ¿QUÉ SON Y QUE NO SON LAS COOKIES/DATOS DE NAVEGACIÓN?</b
        ><br /><br />Las cookies/datos de navegación son pequeños archivos de
        información o fragmentos de texto enviados por un sitio web o Aplicación
        tecnológica de propiedad de MARÍA GONZÁLEZ o alguna de sus sociedades
        controladas o que se encuentren bajo el mismo nivel de control (en
        adelante MARÍA GONZÁLEZ) y que se almacenan en el navegador o
        dispositivo del usuario. A través de las cookies, los sitios web
        recuerdan información sobre la visita de los usuarios, lo que permite
        que se proporcione una mejor y más segura experiencia de navegación en
        el mismo. Las cookies se asocian tanto a usuarios anónimos, es decir,
        aquellos que visitan los sitios web sin identificarse o registrarse,
        como aquellos que si lo hacen. <br /><br />Por otro lado, las cookies no
        son un virus o cualquier otro tipo de programa malicioso que pueda dañar
        dispositivos de los usuarios. Adicionalmente las cookies no pueden
        borrar ni leer información del computador o dispositivo de los
        usuarios.<br /><br /><b>2. ¿CÓMO OBTIENE ESTA PÁGINA WEB LAS COOKIES?</b
        ><br /><br />Las cookies se crean y/o actualizan en el computador o
        dispositivo del usuario de manera automática, cuando éste accede a un
        sitio web de esta página, lo cual permite a MARÍA GONZÁLEZ o a las
        terceras personas que este contrate, realizar seguimiento a las cookies
        del usuario y por ende a la información que estas cookies contienen u
        obtienen del usuario. Es importante aclarar que las cookies solo son
        leídas por el sitio web que las creó.<br /><br /><b
          >3 ¿QUÉ CLASE DE COOKIES UTILIZA ESTA PÁGINA WEB Y PARA QUÉ LAS
          UTILIZA?</b
        ><br /><br />
        1. Cookies Esenciales. <br />Estas cookies son esenciales para el Uso
        del sitio web, en el sentido de facilitar el proceso de registro en el
        mismo, así como permitir a los usuarios un acceso más rápido y seguro a
        los servicios seleccionados dentro del sitio web. Sin estas cookies, es
        posible que MARÍA GONZÁLEZ no les pueda ofrecer a los usuarios ciertos
        servicios dentro del sitio web y éste puede que no funcione con
        normalidad. <br /><br />
        2. Cookies Funcionales. <br />Mediante el uso de las cookies
        funcionales, es posible para esta página web o el tercero que este
        contrate, personalizar los servicios que se ofrecen en el sitio web,
        facilitando a cada usuario información que es o puede ser de su interés,
        en atención al uso que realiza de los servicios y a las páginas
        específicas que visita dentro del sitio web. A través de estas cookies,
        esta página web o las terceras personas que contrate, podrán ofrecer a
        los usuarios una mejor experiencia y mejor rendimiento en la navegación
        dentro del sitio web. <br /><br />
        4. Cookies de Análisis o Desempeño. <br />A través de las cookies de
        análisis o desempeño, este sitio web o el tercero que éste contrate,
        puede realizar distintos análisis y estudios de la información
        recolectada, con el fin de mejorar los productos y servicios que este
        sitio web ofrece a los usuarios. <br /><br />Este sitio web y los
        terceros con los que contrate servicios para el uso de cookies, son los
        únicos que podrán acceder a la información almacenada en las cookies que
        se han instalado dentro del equipo del usuario. La información
        almacenada en las cookies no puede ser leída por otros usuarios, ni
        estos podrán acceder a la misma.<br /><br /><b
          >4 ¿PUEDO DESHABILITAR LA INSTALACIÓN Y USO DE COOKIES POR PARTE DE
          ESTE SITIO WEB?</b
        ><br /><br />El usuario puede deshabilitar tanto la instalación de las
        cookies como el uso de las mismas por parte de este sitio web. Para
        deshabilitar la instalación y uso de cookies el usuario deberá realizar
        el procedimiento por navegador tal como se indica a continuación:<br /><br />
        • Internet Explorer: Herramientas -&gt; Opciones de Internet -&gt;
        Privacidad -&gt; Configuración.<br />Para más información, puede
        consultar el Soporte de Microsoft o la Ayuda del navegador<br /><br />
        • Firefox: Herramientas -&gt; Opciones -&gt; Privacidad -&gt; Historial
        -&gt; Configuración Personalizada.<br />Para más información, puede
        consultar el Soporte de Mozilla o la Ayuda del navegador.<br /><br />
        • Chrome: Configuración -&gt; Mostrar opciones avanzadas -&gt;
        Privacidad -&gt; Configuración de contenido. Para más información, puede
        consultar el Soporte de Google o la Ayuda del navegador.<br /><br />
        • Safari: Preferencias -&gt; Seguridad.<br />Para más información, puede
        consultar el Soporte de Apple o la Ayuda del navegador.<br /><br />Los
        sistemas de opt-out indicados anteriormente pueden conllevar que se
        instale en su equipo una cookie "de rechazo" para que funcione su
        elección de desactivación.<br /><br />Adicionalmente, existen otras
        herramientas de terceros, disponibles on line, que permiten a los
        usuarios detectar las cookies en cada sitio web que visita y gestionar
        su desactivación, por ejemplo: <br />
        • Ghostery: http://www.ghostery.com/faq <br />
        • Vanilla Cookie Manager
        https://chrome.google.com/webstore/detail/vanilla-cookie-manager/
        <br /><br /><b>5 ¿QUÉ OCURRE SI SE DESHABILITAN LAS COOKIES?</b
        ><br /><br />Si el usuario deshabilita la instalación o el uso de las
        cookies para los sitios web, podrá perder o afectar algunas
        funcionalidades del sitio, como por ejemplo:<br /><br />
        1. Rapidez en el uso de algún servicio dentro de los sitios web.
        <br /><br /><b
          >6. ¿ESTE SITIO WEB COMPARTE LA INFORMACIÓN OBTENIDA A TRAVÉS DE LAS
          COOKIES CON TERCERAS PERSONAS?</b
        ><br /><br />Este sitio web podrá compartir información obtenida a
        través de las cookies con personas externas o terceros (aliados,
        clientes, proveedores o empresas vinculadas a este sitio web), con el
        fin de mejorar la usabilidad y servicios al usuario. Así mismo, la
        información que se recibe a través de las cookies será utilizada por
        este sitio web y los anteriores terceros, para los fines descritos en el
        presente documento, los indicados en nuestra Política de privacidad
        (www.mariagonzalez.co/privacy), y cualquiera de sus actualizaciones.
        <br /><br /><b
          >7. ¿DÓNDE ESTA ALMACENADA LA INFORMACIÓN OBTENIDA A TRAVÉS DE LAS
          COOKIES?</b
        ><br /><br />Este sitio web podrá contratar terceras personas encargadas
        de almacenar y obtener la información a través de las cookies, o que
        incluyan cookies dentro de los sitios web de este sitio web, personas
        que podrán estar localizadas dentro de Colombia o en el exterior. Así
        mismo, este sitio web podrá entregar a terceros, la información que se
        obtenga de las cookies para crear perfiles de usuarios, ofrecer campañas
        personalizadas, sin que lo anterior, implique entrega de información
        personal. <br /><br />Lo invitamos a revisar las políticas de privacidad
        de los terceros contratados por este sitio web, en el siguiente
        enlance:<br />
        https://policies.google.com/privacy?hl=es<br /><br />La información
        obtenida a través de estas cookies, referida al equipo del usuario,
        podrá ser combinada con sus datos personales sólo si usted está
        registrado en un sitio web de este sitio web.<br /><br /><b
          >8. ¿QUÉ DEBO TENER EN CUENTA SOBRE LA POLÍTICA DE USO DE COOKIES Y
          DÓNDE ME PUEDO CONTACTAR EN CASO DE DUDAS?</b
        ><br /><br />La presente Política contiene la información necesaria que
        debe conocer todo usuario de los sitios web sobre el uso de las cookies
        que realiza este sitio web o los terceros que este contrate. Este sitio
        web podrá modificar el presente documento en cualquier momento y sin
        previo aviso para mantenerlos vigentes y actualizados. Por lo anterior,
        recomendamos a los usuarios revisar la fecha de elaboración o
        actualización de los mismos, la cual se establece al final del presente
        documento. <br /><br />En caso de dudas o inquietudes acerca del uso de
        cookies o sobre cualquiera de los puntos detallados en esta Política,
        por favor escriba al siguiente correo electrónico:
        info@mariagonzalez.co.
        <br />
        <br />
        28 de mayo de 2020.<br />
      </p>
    </div>
  </div>
</div>
<app-contact></app-contact>
