import { Component, OnInit } from "@angular/core";
import { PortafolioService } from "src/app/services/portafolio.service";
import { Portafolio } from "src/models/portafolio";
import { trigger, transition, style, animate } from "@angular/animations";

@Component({
  selector: "app-portafolio",
  templateUrl: "./portafolio.component.html",
  styleUrls: ["./portafolio.component.scss"],
  animations: [
    trigger("fadeIn", [
      transition(":enter", [
        style({ opacity: "0" }),
        animate("1s ease-out", style({ opacity: "1" })),
      ]),
      transition(":leave", [
        style({ opacity: "1" }),
        animate("0.35s ease-out", style({ opacity: "0" })),
      ]),
    ]),
    trigger("ap", [
      transition(":enter", [
        style({ transform: "translateY(20%)" }),
        animate("0.5s ease-out", style({ transform: "translateY(0%)" })),
      ]),
    ]),
  ],
})
export class PortafolioComponent implements OnInit {
  projects: Portafolio[];
  allProjects: Portafolio[];
  click = false;
  constructor(private portafolioService: PortafolioService) {}

  ngOnInit() {
    this.getProjects();
  }

  getProjects() {
    this.portafolioService.getProjects().subscribe((projects) => {
      this.allProjects = projects;
      this.projects = projects.slice(0, 6).sort((a, b) => {
        if (a.id > b.id) {
          return 1;
        }else{
          return -1
        }
      });
    });
  }
  loadMore() {
    setTimeout(() => {
      this.projects = this.allProjects.slice(0, this.projects.length + 6);
    }, 350);
  }
}
