import { NgModule } from "@angular/core";
import {
  Routes,
  RouterModule,
  ExtraOptions,
  PreloadAllModules,
} from "@angular/router";
import { CookiesComponent } from "./cookies/cookies.component";
import { PrivacyComponent } from "./privacy/privacy.component";
import { ProyectoComponent } from "./proyecto/proyecto.component";
import { BrochureComponent } from "./downloads/brochure/brochure.component";

export function beResponsive() {
  if (window.innerWidth < 768) {
    return 84;
  }
  if (window.innerWidth < 1024) {
    return 84;
  }
  if (window.innerWidth >= 1024) {
    return 100;
  }
}
const routerOptions: ExtraOptions = {
  scrollPositionRestoration: "enabled",
  anchorScrolling: "enabled",
  scrollOffset: [0, window.innerWidth > 768 ? 64 : 84],
  onSameUrlNavigation: "reload",
  preloadingStrategy: PreloadAllModules,
};

const routes: Routes = [
  // {
  //   path: "",
  //   loadChildren: () =>
  //     import("./landing-components/landing-components.module").then(function (
  //       m
  //     ) {
  //       m.LandingComponentsModule;
  //     }),
  // },
  // {
  //   path: "proyecto",
  //   loadChildren:
  //     "./landing-components/landing-components.module#LandingComponentsModule",
  // },
  { path: "proyecto/:id", component: ProyectoComponent },
  { path: "descargas/brochure.pdf", component: BrochureComponent },
  {
    path: "cookies",
    component: CookiesComponent,
  },
  {
    path: "privacy",
    component: PrivacyComponent,
  },
  {
    path: "",
    loadChildren: () =>
      import("./landing-components/landing-components.module").then(
        (m) => m.LandingComponentsModule
      ),
  },
  {
    path: "**",
    loadChildren: () =>
      import("./landing-components/landing-components.module").then(
        (m) => m.LandingComponentsModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
