import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Portafolio } from "../../models/portafolio";
import { Procesos } from "../../models/procesos";

@Injectable({
  providedIn: "root",
})
export class ProcesosService {
  procesos: Procesos[] = [
    {
      id: 1,
      name: "contacte nuestro estudio.",
      description:
        "Al ponerse en contacto con nosotros, hablaremos un poco de como trabajamos, cuál es su necesidad y como encajarla en nuestros servicios.",
      imageUrl: "../../assets/procesos/img1.jpg",
    },
    {
      id: 2,
      name: "Cuéntenos su proyecto.",
      description:
        "Se realiza una primera reunión que nos ayude a tener en cuenta todas sus ideas y necesidades, en donde hablamos de su proyecto, de los enfoques, los tiempos y los presupuestos, además, para conocer si el proyecto será realizado 100% con nosotros o su necesidad es solo el diseño, para así poder brindar una mejor experiencia.",
      imageUrl: "../../assets/procesos/img2.jpg",
    },
    {
      id: 3,
      name: "Medición del espacio.",
      description:
        "Una vez socializadas las ideas, procedemos con una segunda visita para la medición del espacio y el registro fotográfico, si ya se tiene el plano del lugar, realizaremos la visita para confirmar que lo que este en el plano no haya tenido modificaciones.",
      imageUrl: "../../assets/procesos/img3.jpg",
    },
    {
      id: 4,
      name: "Propuesta de distribución.",
      description:
        "El diseñador realizara una propuesta de distribución espacial, la cual será enviada, analizada y socializada. Al realizar los comentarios, nuevas ideas o adiciones, obtendremos como resultado la solución de planificación ideal para su espacio y en la cual abordaremos el proyecto.",
      imageUrl: "../../assets/procesos/img4.jpg",
    },
    {
      id: 5,
      name: "Desarrollo de conceptos.",
      description:
        "En esta etapa nos divertiremos: El diseñador comienza a realizar propuesta según lo hablado, estas propuestas se presentan de manera digital, por medio de Mood board que se conforma con los materiales, referentes de un estilo, detalles interiores y colores preseleccionados.",
      imageUrl: "../../assets/procesos/img5.jpg",
    },
    {
      id: 6,
      name: "Selección de materiales.",
      description:
        "Una vez revidado el Mood board y teniendo claro nuestra propuesta, procedemos a la elección de materiales de muebles, pisos, paredes, etc.",
      imageUrl: "../../assets/procesos/img6.jpg",
    },
    {
      id: 7,
      name: "Render 3D Fotorrealista.",
      description:
        "Para que pueda tener una visión más amplia y certera de como quedara su espacio, realizamos renders 3d Realistas de los espacios que vamos a intervenir, en donde se muestran los materiales y acabados escogidos anteriormente.",
      imageUrl: "../../assets/procesos/img7.jpg",
    },
    {
      id: 8,
      name: "Conjunto de planos necesarios para la ejecución del proyecto.",
      description:
        "La documentación técnica, incluye todos los planos necesarios en la obra, así como también los acabados en diseño.",
      imageUrl: "../../assets/procesos/img8.jpg",
    },
    {
      id: 9,
      name: "Puesta en marcha del proyecto.",
      description:
        "Para ello se contratara a un tercero calificado para llevar a cabo el proyecto, a este equipo se le entregaran todas las especificaciones, planos y renders. Se firmara un contrato que especifique los tiempos de entrega y los costos de la obra.",
      imageUrl: "../../assets/procesos/img9.jpg",
    },
    {
      id: 10,
      name: "Supervisión de la obra.",
      description:
        "El proceso de construcción está totalmente controlado por el diseñador encargado. Para esta supervisión, se realizan visitas de campo para ver los avances de obra, verificar el cumplimiento con los tiempos establecidos, reuniones con el arquitecto encargado y los maestros de cuadrilla, se realiza la toma de detalles de obra y las correcciones necesarias.",
      imageUrl: "../../assets/procesos/img10.jpg",
    },
    {
      id: 11,
      name: "Entrega del proyecto.",
      description:
        "Se realiza la visita con su acompañamiento para hacer entrega oficial del proyecto tal como se le presento en el render 3D, se realiza el recorrido por todas las instalaciones verificando todo en total funcionamiento y satisfacción.",
      imageUrl: "../../assets/procesos/img11.jpg",
    },
  ];
  constructor() {}

  getProcesos(): Observable<Procesos[]> {
    return of(this.procesos);
  }
}
