<div class="container">
  <div
    *ngFor="let image of homeImages"
    [ngClass]="{
      'show-me': image.id === idToHide + 1
    }"
    class="carousel hide fade-in"
    id="parent{{ image.id }}"
  >
    <div id="{{ image.id }}" class="image-background" #background>
      <div class="text-background">
        <span>
          {{ image.description }}
        </span>
        <div>
          <p (click)="onConocenos()">
            <span class="caret"
              ><div class="caret-animation">
                <i class="fas fa-caret-down"></i></div
            ></span>
            <span class="conocenos">Conócenos</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="shape">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      viewBox="0 0 1440 100"
    >
      <path
        fill="white"
        shape-rendering="optimizeQuality"
        fill-opacity="1"
        d="M 0 100 L 400 100 C 800 100 1100 100 1450 0 L 1450 100 L 0 100 "
      />
    </svg>
  </div>
  <!-- <div style="height: 500px; width: 100%;"></div> -->
</div>
