<div class="container">
  <div class="grid">
    <section class="conocenos__cont">
      <h1 class="conocenos__cont__title">
        Política de Tratamiento de datos
      </h1>
      <div class="conocenos__cont__content terminos">
        <div class="content">
          <p></p>
          <p><strong>1. Objetivo </strong></p>
          <p>
            Establecer las directrices generales para el tratamiento de la
            información personal recolectada y administrada por MARÍA GONZÁLEZ.
          </p>

          <p><strong>2. Alcance </strong></p>
          <p>
            Este manual interno es aplicable al tratamiento de los datos de
            carácter personal que estén contenidos en bases de datos de la
            Compañía.
          </p>

          <p><strong> 3. Definiciones </strong></p>
          <p>
            Los términos utilizados en la presente Política, relacionados en
            orden alfabético, tendrán el significado establecido a continuación:
          </p>
          <p>
            • <strong>Autorización:</strong> Consentimiento previo, expreso e
            informado del Titular para llevar a cabo el tratamiento de datos
            personales.
          </p>
          <p>
            • <strong>Base de datos:</strong> Conjunto organizado de datos
            personales que sea objeto de tratamiento.
          </p>
          <p>
            • <strong>Causahabiente:</strong> Persona que por sucesión o
            sustitución adquiere los derechos de otra persona.
          </p>
          <p>
            • <strong>Consulta:</strong> Se trata del derecho del Titular a ser
            informado por el responsable del tratamiento, previa solicitud,
            respecto al origen, uso y finalidad que le han dado a sus datos
            personales.
          </p>
          <p>
            • <strong>Dato personal:</strong> Cualquier información vinculada o
            que pueda asociarse a una o varias personas naturales determinadas o
            determinables.
          </p>
          <p>
            • <strong>Dato público:</strong> Es el dato que no sea privado o
            sensible. Son considerados datos públicos, entre otros, los datos
            relativos al estado civil de las personas, a su profesión u oficio y
            a su calidad de comerciante o de servidor público. Por su
            naturaleza, los datos públicos pueden estar contenidos, entre otros,
            en registros públicos, documentos públicos, gacetas y boletines
            oficiales, sentencias judiciales debidamente ejecutoriadas que no
            estén sometidas a reserva.
          </p>
          <p>
            • <strong>Dato privado:</strong> Es un dato personal que, por su
            naturaleza íntima o reservada, solo interesa a su Titular y para su
            tratamiento requiere de su autorización previa, informada y expresa.
            Puede estar contenido en bases de datos que contengan números
            telefónicos y correos electrónicos personales, datos laborales,
            infracciones administrativas o penales, administrados por entidades
            tributarias, financieras, gestoras y de servicios comunes de la
            Seguridad Social; bases de datos sobre solvencia patrimonial o de
            crédito, bases de datos con información suficiente para evaluar la
            personalidad del Titular y bases de datos de los responsables de
            operadores que presten servicios de comunicación electrónica.
          </p>
          <p>
            • <strong>Dato semiprivado: </strong>Es aquel que no tiene
            naturaleza íntima, reservada, ni pública y cuyo conocimiento o
            divulgación puede interesar no sólo a su Titular sino a cierto
            sector o grupo de personas o a la sociedad en general, como son:
            bases de datos que contengan Información financiera, crediticia,
            comercial, de servicios y la proveniente de terceros países.
          </p>
          <p>
            • <strong>Dato sensible:</strong> Se entiende por datos sensibles
            aquellos que afectan la intimidad del Titular o cuyo uso indebido
            puede generar su discriminación, tales como aquellos que revelen su
            origen racial o étnico, su orientación política, sus convicciones
            religiosas o filosóficas, su pertenencia a sindicatos,
            organizaciones sociales, de derechos humanos o que promuevan
            intereses de cualquier partido político o que garanticen los
            derechos y garantías de partidos políticos de oposición, así como
            los datos relativos a la salud, a la vida sexual y los datos
            biométricos.
          </p>
          <p>
            • <strong>Encargado del tratamiento:</strong> Persona natural o
            jurídica, pública o privada, que por sí misma o en asocio con otros,
            realice el tratamiento de datos personales por cuenta del
            responsable del tratamiento.
          </p>
          <p>
            • <strong>Incidencia:</strong> Los incidentes se refieren a
            cualquier evento en los sistemas de información o bases de datos
            manuales o sistematizadas que atenten contra la seguridad de los
            datos personales en ellos almacenados.
          </p>
          <p>
            • <strong>Oficial de protección de datos: </strong>Es la persona
            natural que asume la función de coordinar la implementación del
            marco legal en protección de datos personales, que dará trámite a
            las solicitudes de los titulares, para el ejercicio de los derechos
            a que se refiere la Ley 1581 de 2012.
          </p>
          <p>
            • <strong>Responsable del tratamiento:</strong> Persona natural o
            jurídica, pública o privada, que por sí misma o en asocio con otros,
            decida sobre la base de datos y su tratamiento.
          </p>
          <p>
            •
            <strong>Responsable de administrar las bases de datos: </strong
            >Colaborador encargado de controlar y coordinar la adecuada
            aplicación de la Política de tratamiento de los datos una vez
            almacenados en una base específica, así como de poner en práctica
            las directrices que dicten el Responsable del tratamiento y el
            Oficial de protección de datos.
          </p>
          <p>
            • <strong>Reclamo de corrección:</strong> Derecho del Titular a que
            se actualicen, rectifiquen o modifiquen aquellos datos parciales,
            inexactos, incompletos, fraccionados, o que induzcan a error.
          </p>
          <p>
            • <strong>Reclamo de infracción: </strong>Derecho del Titular a
            solicitar que se subsane el incumplimiento de la normativa en
            materia de protección de datos.
          </p>
          <p>
            • <strong>Reclamo de revocación:</strong> Derecho del Titular a
            dejar sin efecto la autorización previamente prestada para el
            tratamiento de sus datos personales.
          </p>
          <p>
            • <strong>Reclamo de supresión:</strong> Derecho del Titular a que
            se supriman los datos que resulten inadecuados, excesivos o que no
            respeten los principios, derechos y garantías constitucionales y
            legales.
          </p>
          <p>
            • <strong>Titular:</strong> Persona natural cuyos datos personales
            sean objeto de tratamiento.
          </p>
          <p>
            • <strong>Tratamiento: </strong>Cualquier operación o conjunto de
            operaciones sobre los datos personales del Titular, tales como la
            recolección, almacenamiento, uso, circulación o supresión.
          </p>
          <p>
            • <strong>Aviso de privacidad: </strong>Comunicación verbal o
            escrita generada por el Responsable, dirigida al Titular para el
            tratamiento de sus datos personales, mediante la cual se le informa
            acerca de la existencia de la Política de tratamiento de información
            que le será aplicable, la forma de acceder a la misma y las
            finalidades del tratamiento que se pretende dar a los datos
            personales.
          </p>
          <p>
            • <strong>Transferencia: </strong>La transferencia de datos tiene
            lugar cuando el Responsable y/o encargado del tratamiento de datos
            personales, ubicado en Colombia, envía la información o los datos
            personales a un receptor, que a su vez es responsable del
            tratamiento y se encuentra dentro o fuera del país. Transmisión:
            Tratamiento de datos personales que implica la comunicación de los
            mismos dentro o fuera del territorio de la República de Colombia,
            cuando tenga por objeto la realización de un tratamiento determinado
            por cuenta de la persona encargada (Responsable).

            <strong></strong>
          </p>
          <p><strong>4. Disposiciones generales</strong></p>
          <strong></strong>
          <p><strong>4.1 Introducción</strong></p>
          <p>
            MARÍA GONZÁLEZ (en adelante la Compañía), sociedad colombiana
            identificada con el NIT 830.037.946-3, en cumplimiento de la
            normatividad vigente que regula la protección de datos personales y
            establece las garantías legales que amparan a todas las personas en
            Colombia para asegurar el debido tratamiento de sus datos, establece
            la siguiente Política para el tratamiento de datos personales.
            <strong></strong>
          </p>
          <p><strong>4.2. Normatividad aplicable</strong></p>
          <p>
            La siguiente es la normatividad vigente, con base en la cual se
            concebirá la presente Política: &shy; Constitución Política de
            Colombia de 1991, artículos 15 y 20. &shy; Ley 1581 de 2012. &shy;
            Decreto 1074 de 2015: capítulos 25 y 26, compilatorio de los
            Decretos 1377 de 2013 y 886 de 2014. &shy; Circular Externa 005 de
            2017. &shy; Decreto 1115 de 2017 (Plazos de inscripción bases de
            datos). <strong></strong>
          </p>
          <p><strong>4.3. Principios de la protección de datos </strong></p>
          <p>
            Los principios que regirán el tratamiento y la protección de datos
            personales en la Compañía son:
          </p>
          <p>
            • Legalidad: El tratamiento de los datos es una actividad reglada
            que debe sujetarse a lo establecido en la Ley 1581 de 2012, el
            Decreto 1377 de 2013 compilado en el Capítulo 25 del Decreto 1074 de
            2015 y en las demás disposiciones que la desarrollen.
          </p>
          <p>
            • Finalidad: El tratamiento debe obedecer a una finalidad legítima
            de acuerdo con la Constitución y la Ley, la cual debe ser informada
            al Titular.
          </p>
          <p>
            • Libertad: El tratamiento solo puede ejercerse con el
            consentimiento previo, expreso e informado del Titular. Los datos
            personales no podrán ser obtenidos o divulgados sin previa
            autorización, o en ausencia de mandato legal o judicial que revele
            el consentimiento.
          </p>
          <p>
            • Veracidad o calidad: La información sujeta a tratamiento debe ser
            veraz, completa, exacta, actualizada, comprobable y comprensible. Se
            prohíbe el tratamiento de datos parciales, incompletos, fraccionados
            o que induzcan a error.
          </p>
          <p>
            • Transparencia: En el tratamiento debe garantizarse el derecho del
            Titular a obtener del Responsable o del Encargado del tratamiento,
            en cualquier momento y sin restricciones, información acerca de la
            existencia de datos que le conciernen.
          </p>
          <p>
            • Acceso y circulación restringida: El tratamiento se sujeta a los
            límites que se derivan de la naturaleza de los datos personales, de
            las disposiciones de la Ley 1581 de 2012 y la Constitución. En este
            sentido, el tratamiento solo podrá hacerse por personas autorizadas
            por el Titular y/o por las personas previstas en la Ley. Los datos
            personales, salvo la información pública, no podrán estar
            disponibles en internet y otros medios de divulgación o comunicación
            masiva, salvo que el acceso sea técnicamente controlable para
            brindar un conocimiento restringido solo a los titulares o terceros
            autorizados conforme a la Ley.
          </p>
          <p>
            • Seguridad: La información sujeta a tratamiento por las personas
            encargadas se deberá manejar con las medidas técnicas, humanas y
            administrativas que sean necesarias para otorgar seguridad a los
            registros y evitar su adulteración, pérdida, consulta, uso o acceso
            no autorizado o fraudulento.
          </p>
          <p>
            • Confidencialidad: Todas las personas que intervengan en el
            tratamiento de datos personales que no tengan la naturaleza de
            públicos, están obligadas a garantizar la reserva de la información,
            inclusive después de finalizada su relación con alguna de las
            labores que comprende el tratamiento, pudiendo solo realizar
            suministro o comunicación de datos personales cuando ello
            corresponda al desarrollo de las actividades autorizadas en la Ley
            1581 de 2012 y en los términos de la misma. <strong></strong>
          </p>
          <p>
            <strong>4.4. Funciones del Oficial de protección de datos</strong>
          </p>
          <p>
            La Compañía contará con un Oficial de protección de datos personales
            quien cumplirá las siguientes funciones:
          </p>
          <p>
            Controlar el inventario de bases de datos, clasificarlo según su
            tipo, actualizarlo y realizar el registro ante la Superintendencia
            de Industria y Comercio.
          </p>
          <p>
            Reportar las incidencias de seguridad de la información ante la
            Superintendencia de Industria y Comercio y hacer seguimiento a los
            planes de acción establecidos.
          </p>
          <p>
            Asegurar que los responsables de las bases de datos reporten las
            relaciones con encargados y monitorear que estas se encuentren
            soportadas con los correspondientes contratos de transmisión de
            datos.
          </p>
          <p>
            Reportar semestralmente a la Dirección General la actualización de
            riesgos de seguridad de los datos, resultados de auditorías y
            eventuales incidencias de seguridad ocurridas durante el período.
          </p>
          <p>
            Adelantar un programa anual de capacitación sobre políticas,
            procedimientos y controles relacionados con el tratamiento de datos
            personales.
          </p>
          <p>
            Servir de enlace a las demás áreas de la Compañía para asegurar y
            coordinar una implementación transversal de este manual.
          </p>
          <p>
            Obtener las declaraciones de conformidad por parte de la
            Superintendencia de Industria y Comercio en el caso de
            transferencias o trasmisiones internacionales de datos, en caso de
            ser requerido.
          </p>
          <p>
            Velar por la ejecución de programas de auditoría interna para
            verificar el cumplimiento de la Política de datos personales.
          </p>
          <p>
            Atender de forma diligente y dentro de los términos de Ley, las
            solicitudes y reclamaciones de los titulares de datos personales.

            <strong></strong>
          </p>
          <p><strong>5. Tratamiento de los datos personales</strong></p>
          <strong></strong>
          <p><strong>5.1. Responsable</strong></p>
          <p>
            En el presente Manual de políticas y procedimientos, el Responsable
            del tratamiento de las bases de datos personales será MARÍA
            GONZÁLEZ, cuya información general y de contacto se indica a
            continuación:
          </p>
          &shy;
          <p>Dirección: TR 32AS # 33 -36, Envigado &shy;</p>
          <p>Correo electrónico: info@mariagonzalez.co &shy;</p>
          <p><strong>5.2. Finalidades</strong></p>
          <p>
            En desarrollo de su actividad empresarial, la Compañía podrá
            recolectar, usar y tratar datos personales conforme a la Política de
            Tratamiento de Datos Personales contenida en este documento y las
            finalidades autorizadas por cada Titular de los datos, siendo estas
            informadas de manera previa y expresa con observancia de los
            requisitos legales que señala la Ley y la Constitución Política.

            <strong></strong>
          </p>
          <p>
            <strong
              >5.3 Autorización del Titular de los datos personales</strong
            >
          </p>
          <p>
            De acuerdo con la normatividad vigente, el tratamiento que dará la
            Compañía a los datos personales requiere de una autorización previa
            e informada del Titular, la cual se obtendrá por cualquier medio,
            con antelación al tratamiento, y podrá ser objeto de consulta
            posterior, excepto en los casos expresamente excluidos por la Ley.
            La Compañía obtendrá la autorización antes mencionada, mediante el
            uso de formatos de solicitud de autorización físicos, de audio o
            digitales, en los cuales se informará al Titular:
          </p>
          <p>
            • El tratamiento al cual serán sometidos sus datos personales y la
            finalidad del mismo.
          </p>
          <p>
            • El carácter facultativo de la respuesta a las preguntas que le
            sean hechas, cuando estas versen sobre datos sensibles o sobre los
            datos de menores de edad.
          </p>
          <p>
            • Los derechos que le asisten como Titular y los canales de
            atención.
          </p>
          <p>
            • La identificación, la dirección física o electrónica y el teléfono
            de la Compañía. La Compañía conservará de manera física y/o digital
            las autorizaciones diligenciadas por los titulares, para atender
            solicitudes de los mismos o de las entidades de control.
            <strong></strong>
          </p>
          <p><strong>5.4 Datos de menores</strong></p>
          <p>
            En el evento de que la Compañía, en desarrollo de su actividad
            empresarial o en cumplimiento de alguna norma legal, requiera
            obtener datos personales de menores de edad, solicitará al
            Representante Legal del menor la autorización para el tratamiento de
            los mismos, previa validación de su acreditación como representante
            o apoderado. La Compañía velará por el uso legal y adecuado de los
            datos pertenecientes a menores de edad asegurando que se respeten
            sus intereses y derechos fundamentales. <strong></strong>
          </p>
          <p><strong>5.5 Datos biométricos</strong></p>
          <p>
            Los datos biométricos almacenados en las bases de datos serán
            recolectados y tratados por motivos estrictamente de seguridad, para
            verificar la identidad personal y realizar control de acceso a los
            empleados, clientes y visitantes. Los mecanismos biométricos de
            identificación capturan, procesan y almacenan información
            relacionada con, entre otros, los rasgos físicos de las personas
            (las huellas dactilares, reconocimiento de voz y los aspectos
            faciales), para establecer o “autenticar” la identidad de cada
            sujeto. La administración de las bases de datos biométricos se
            ejecutará con medidas de seguridad que garantizarán el debido
            cumplimiento de los principios y las obligaciones derivadas de Ley
            Estatutaria en Protección de Datos asegurando, además, la
            confidencialidad y reserva de la información de los titulares.
            <strong></strong>
          </p>
          <p><strong>5.6. Seguridad de los datos personales</strong></p>
          <p>
            • Administración de los riesgos La Compañía identificará y evaluará
            de manera periódica los riesgos que atentan contra la seguridad de
            los datos personales objeto de tratamiento, en función de su
            probabilidad de ocurrencia e impacto, estableciendo controles que
            mitiguen de manera razonable dichos riesgos. La efectividad de los
            controles se monitoreará regularmente, de modo que se puedan
            implementar acciones correctivas y de mejora.
          </p>
          <p>
            • Seguridad informática Con el propósito de proteger y preservar la
            integridad, confidencialidad y disponibilidad de los datos
            personales, la Compañía tiene establecidos procedimientos y
            estándares de seguridad de la información, los cuales serán
            susceptibles de actualización para ajustarlos a nuevas necesidades o
            cambios en la normatividad aplicable. La Compañía ejecuta y tiene
            documentadas las medidas de seguridad aplicables a la protección de
            datos personales.
          </p>
          <p>
            • Gestión documental La Compañía establecerá procedimientos y
            medidas de seguridad para las bases de datos no automatizadas que
            contengan datos personales, aplicando los criterios que aseguren su
            custodia, conservación, localización, disposición final y que
            permitan a los titulares ejercer su derecho de consulta y/o reclamo.
            Adicionalmente, implementará los controles necesarios para mitigar
            razonablemente los riesgos de acceso no autorizado, adulteración,
            pérdida, deterioro y reproducción indiscriminada. <strong></strong>
          </p>
          <p><strong>5.7. Transmisión de datos personales</strong></p>
          <p>
            En virtud de la existencia de un vínculo contractual con un tercero,
            y en caso de ser necesario suministrar datos personales, la Compañía
            suscribirá un Contrato de transmisión de datos personales
            debidamente avalado por el Departamento Jurídico y firmado por el
            Representante Legal de la Compañía, en el cual exigiremos a nuestro
            Encargado de tratamiento:
          </p>
          <p>
            Contar con una política formal para el manejo de datos personales,
            que garantice el cumplimiento de la normatividad vigente en relación
            con la protección de datos personales y la atención oportuna de
            consultas y reclamos de los titulares.
          </p>
          <p>
            Conservar la información bajo las condiciones de seguridad
            necesarias para impedir su adulteración, pérdida, consulta, uso o
            acceso no autorizado o fraudulento.
          </p>
          <p>
            Realizar oportunamente la actualización, rectificación o supresión
            de los datos, según los requerimientos normativos y contractuales.
          </p>
          <p>
            Actualizar la información reportada por la Compañía, dentro de los
            cinco (5) días hábiles contados a partir de su recibo.
          </p>
          <p>
            Registrar oportunamente en la base de datos entregada por la
            Compañía las leyendas de “Reclamo en trámite” y/o “Información en
            discusión judicial”, según aplique.
          </p>
          <p>
            Dar tratamiento, a nombre del Responsable, a los datos personales
            conforme a los principios que los tutelan.
          </p>
          <p>
            Salvaguardar la seguridad de las bases de datos en los que se
            contengan datos personales.
          </p>
          <p>
            Guardar confidencialidad respecto del tratamiento de los datos
            personales.
          </p>
          <p>Demás obligaciones contenidas en la Ley 1581 de 2012.</p>
          <p>
            Cumplir las instrucciones y requerimientos que imparta la
            Superintendencia de Industria y Comercio. De la misma forma, en
            dichos contratos la Compañía se obligará a:
          </p>
          <p>
            Garantizar que la información suministrada al Encargado del
            tratamiento sea veraz, completa, exacta, actualizada, comprobable y
            comprensible. &shy;
          </p>
          <p>
            Actualizar la información, comunicando en forma oportuna al
            Encargado, todas las novedades respecto de los datos que previamente
            le haya suministrado y adoptar las demás medidas necesarias para que
            la información suministrada a este, se mantenga actualizada.
          </p>
          <p>
            Rectificar la información cuando sea incorrecta y comunicar lo
            pertinente al Encargado.
          </p>
          <p>
            Suministrar al Encargado, según el caso, únicamente datos cuyo
            tratamiento esté previamente autorizado por el Titular, de
            conformidad con la normatividad vigente.
          </p>
          <p>
            Demás obligaciones contenidas en la Ley 1581 de 2012. Las
            transmisiones internacionales de datos personales que se efectúen
            entre la Compañía y un Encargado para permitir que este realice el
            tratamiento por cuenta de la Compañía, no requerirán ser informados
            al Titular, ni contar con su consentimiento, siempre que se haya
            suscrito el Contrato de transmisión de datos personales.
            <strong></strong>
          </p>
          <p><strong>5.8 Transferencia de datos personales</strong></p>
          <p>
            En caso de que la Compañía decida efectuar transferencia de datos
            personales a países que no proporcionen estándares adecuados de
            seguridad y protección, la Compañía cumplirá con las disposiciones
            contenidas en el Título VIII de la Ley 1581 de 2012, la Circular
            Externa 005 de 2017 y demás normas concordantes. <strong></strong>
          </p>
          <p>
            <strong
              >5.9 Entrega de datos personales a autoridades oficiales</strong
            >
          </p>
          <p>
            Cuando una entidad pública o administrativa en ejercicio de sus
            funciones legales, o por orden judicial, se soliciten a la Compañía
            el acceso y/o entrega de datos personales contenidos en cualquiera
            de sus bases de datos, se verificará la legalidad de la petición, la
            pertinencia de los datos solicitados en relación con la finalidad
            expresada por la autoridad, y se suscribirá un acta de la entrega de
            la información personal solicitada, precisando la obligación de
            garantizar los derechos del Titular, tanto al funcionario que hace
            la solicitud, a quien la recibe, así como a la entidad requirente.
            <strong></strong>
          </p>
          <p>
            <strong>
              5.10 Inscripción ante el Registro Nacional de Bases de Datos
              (RNBD)</strong
            >
          </p>
          <p>
            El término para registrar las bases de datos en el RNBD será el
            establecido legalmente. Las bases de datos que se creen con
            posterioridad a ese plazo, deberán inscribirse dentro de los dos (2)
            meses siguientes, contados a partir de su creación.
            <strong></strong>
          </p>
          <p>
            <strong
              >5.11. Procedimiento de notificación, gestión y respuesta ante
              incidencias</strong
            >
          </p>
          <p>
            La Compañía establecerá un procedimiento de notificación, gestión y
            respuesta de incidencias con el fin de garantizar la
            confidencialidad, disponibilidad e integridad de la información
            contenida en las bases de datos que están bajo su responsabilidad.
          </p>
          <p>
            Todos los usuarios y responsables de procedimientos, así como
            cualquier persona que tenga relación con el almacenamiento,
            tratamiento o consulta de las bases de datos recogidas en este
            documento, conocerán el procedimiento para actuar en caso de
            incidencia. El procedimiento de notificación, gestión y respuesta
            ante incidencias es el siguiente:
          </p>
          <p>
            Cuando una persona tenga conocimiento de una incidencia (Pérdida,
            hurto y/o acceso no autorizado) que afecte o pueda afectar la
            confidencialidad, disponibilidad e integridad de la información
            protegida de la empresa, alguno de los Encargados deberá comunicarlo
            de manera inmediata al Oficial de protección de datos, describiendo
            detalladamente el tipo de incidencia producida, indicando las
            personas que hayan podido tener relación con la misma, la fecha y
            hora en que se produjo, la persona que notifica la incidencia, la
            persona a quien se le comunica y los efectos que ha producido.
          </p>
          <p>
            Una vez comunicada la incidencia se debe solicitar al Oficial de
            protección de datos un acuse de recibo en el que conste la
            notificación de la incidencia con todos los requisitos enumerados
            anteriormente.
          </p>
          <p>
            La Compañía llevará un registro de incidencias que debe contener: el
            tipo de incidencia (fraude interno o externo, daños a activos
            físicos, fallas tecnológicas, ejecución y administración de
            procesos), fecha y hora de la misma, persona que la notifica,
            persona a quien se le comunica, efectos de la incidencia y medidas
            correctoras cuando corresponda. Este registro es gestionado por el
            Oficial de protección de datos y quedará consignado en el reporte de
            incidencia junto con el plan de acción.
          </p>
          <p>
            Asimismo, debe implementar los procedimientos para la recuperación
            de los datos cuando aplica, indicando quien ejecuta el proceso, los
            datos restaurados y, en su caso, los datos que han requerido ser
            grabados manualmente en el proceso de recuperación.
          </p>
          <p>
            Adicionalmente, el Oficial de protección de datos informará a la
            Superintendencia de Industria y Comercio, mediante el RNBD dentro de
            los 15 días hábiles siguientes de haber sido detectada.
          </p>
          <p>
            Finalmente, la Compañía notificará del incidente a los titulares,
            cuando se determine que puedan verse afectados de manera
            significativa. <strong></strong>
          </p>
          <p><strong>5.12. Vigencia del tratamiento</strong></p>
          <p>
            Las bases de datos responsabilidad de la Compañía serán objeto de
            tratamiento durante el tiempo que sea razonable y necesario para la
            finalidad para la cual son recabados los datos, obedeciendo a
            intereses legítimos. En caso de ser revocado el consentimiento
            otorgado, la Compañía procederá a la supresión de los datos
            personales en su posesión, salvo que exista una obligación legal o
            contractual que requiera su conservación, la cual será notificada al
            Titular de los datos.

            <strong></strong>
          </p>
          <p>
            <strong
              >6. Derechos de los titulares y procedimiento para
              ejercerlos</strong
            >
          </p>
          <p>
            Los derechos de los titulares respecto a la protección de sus datos
            personales están establecidos en la Ley y corresponden a la
            consulta, acceso y/o suministro de información, prueba de
            autorización para el tratamiento de sus datos y reclamaciones. Estos
            derechos podrán ser exigidos por el Titular, sus causahabientes o
            representantes y/o apoderados legales, debidamente acreditados.
          </p>
          <p>
            La Compañía gestionará de manera gratuita las consultas y
            reclamaciones realizadas por los titulares. El Oficial de protección
            de datos de la Compañía será el encargado de la atención de
            peticiones, consultas y reclamos y puede ser contactado en la
            dirección Calle 12 N.° 34-30 y/o en el correo electrónico:
          </p>
          <p>info@mariagonzalez.co</p>
          <p>
            Una vez agotados los trámites de consulta o reclamo, el Titular,
            causahabiente, representante y/o apoderado legal podrá elevar quejas
            ante la Superintendencia de Industria y Comercio. <strong></strong>
          </p>
          <p>
            <strong>6.1 Consultas, acceso y suministro de información </strong>
          </p>
          <p>
            El Titular de los datos podrá realizar su consulta a MARÍA GONZÁLEZ
            por los siguientes canales: correo electrónico info@mariagonzalez.co
            indicando en el asunto la solicitud; en el área de administración de
            cualquiera de nuestras tiendas a nivel nacional donde se pone a su
            disposición el formato de peticiones, quejas y reclamos (PQR); en la
            sección de Contáctenos de nuestra página web www.mariagonzalez.co o
            por correo ordinario remitido a nuestra oficina en la TR 33AS # 33 -
            36 de ciudad de ENVIGADO.
          </p>
          <p>La solicitud deberá tener los siguientes datos:</p>
          <p>Nombre y apellidos del Titular.</p>
          <p>Petición en que se concreta la solicitud de acceso o consulta.</p>
          <p>Dirección para notificaciones, fecha y firma del solicitante.</p>
          <p>
            Documentos acreditativos de la petición formulada, cuando
            corresponda.
          </p>
          <p>
            De acuerdo con lo establecido en la Ley, el término de la Compañía
            para resolver estas consultas es de diez (10) hábiles contados a
            partir de la fecha de recibo de la misma, y dará respuesta a través
            de correo ordinario o electrónico. Cuando no es posible atender la
            consulta dentro de dicho término, se informará al interesado,
            expresando los motivos de la demora y señalando la fecha en que se
            atenderá su consulta, la cual en ningún caso puede superar los cinco
            (5) días hábiles siguientes al vencimiento del primer término.
            <strong></strong>
          </p>
          <p><strong>6.2 Reclamaciones</strong></p>
          <p>
            En el tratamiento de los datos se distinguen en principio cuatro
            tipos de reclamos: Reclamo de corrección, reclamo de supresión,
            reclamo de revocación y reclamo de infracción, los cuales se
            encuentran definidos en el numeral 3 del presente documento.
          </p>
          <p>
            El Titular de los datos podrá realizar su reclamo a MARÍA GONZÁLEZ
            por los siguientes canales: correo electrónico info@mariagonzalez.co
            indicando en el asunto la solicitud, en el área de administración de
            cualquiera de nuestras tiendas a nivel nacional donde se pone a su
            disposición el formato de peticiones, quejas y reclamos (PQR), en la
            sección de Contáctenos de nuestra página web www.mariagonzalez.co o
            por correo ordinario remitido a nuestra oficina en la TR 32AS # 33 -
            36 de ciudad de ENIGADO.
          </p>
          <p>La solicitud deberá tener los siguientes datos:</p>
          <p>Nombre y apellidos del Titular.</p>
          <p>
            Descripción de los hechos y petición en que se concreta la solicitud
            de corrección, supresión, revocación o infracción.
          </p>
          <p>Dirección para notificaciones, fecha y firma del solicitante.</p>
          <p>
            Documentos acreditativos de la petición formulada que se quiera
            hacer valer, cuando corresponda. Si la reclamación resulta
            incompleta, se requerirá al interesado dentro de los cinco (5) días
            siguientes a la recepción de la reclamación para que subsane las
            fallas. Transcurridos dos (2) meses desde la fecha del
            requerimiento, sin que el solicitante presente la información
            requerida, se entenderá que ha desistido del reclamo.
          </p>
          <p>
            Una vez recibida la reclamación completa, se incluirá en la base de
            datos una leyenda que diga “Reclamo en trámite” y el motivo del
            mismo, en un término no mayor a dos (2) días hábiles. Dicha leyenda
            deberá mantenerse hasta que el reclamo sea decidido.
          </p>
          <p>
            De acuerdo con lo establecido en la Ley, el término de la Compañía
            para resolver estos reclamos es de quince (15) hábiles contados a
            partir de la fecha de recibo de la misma, y dará respuesta a través
            de correo ordinario o electrónico. Cuando no es posible atender la
            consulta dentro de dicho término, se informará al interesado,
            expresando los motivos de la demora y señalando la fecha en que se
            atenderá su consulta, la cual en ningún caso puede superar los ocho
            (8) días hábiles siguientes al vencimiento del primer término.
            <strong></strong>
          </p>
          <p>
            <strong>6.3 Vigencia y divulgación de la presente Política</strong>
          </p>
          La presente Política está vigente desde el 28 de mayo de 2020.
          <p></p>
        </div>
      </div>
      <!-- ATENÇÃO, esse erro prejudica a performance do seu site, o conteudo de nome trastamiento não foi renderizado por nao ser um XDocument válido, erro: - Reference to undeclared entity 'shy'. Line 45, position 6.-->
    </section>
  </div>
</div>
<app-contact></app-contact>
