<div class="container">
  <div class="title-responsive">
    <div class="title">
      <span>Portafolio</span>
    </div>
  </div>
  <div class="projects-main-container">
    <div class="projects" *ngFor="let project of projects" @ap @fadeIn>
      <div
        class="project-image"
        routerLink="../proyecto/{{
          project.name.replace('-', ' ').replace(' ', '-').toLowerCase()
        }}"
      >
        <img
          class="image"
          src="{{ project.imageUrl }}"
          alt="{{ project.name }}"
        />
        <div class="project-information">
          <div class="project-container">
            <div class="project-category">
              <span>
                {{ project.category }}
              </span>
            </div>
            <div class="project-name">
              <span>
                {{ project.name }}
              </span>
            </div>
            <div class="project-location">
              <span *ngIf="project.location"> {{ project.location }} </span>
            </div>
            <div class="project-area" *ngIf="project.area">
              <span> {{ project.area }} </span>
              <span> m²</span>
            </div>
            <div
              class="project-short-description"
              *ngIf="project.shortDescription"
            >
              <span>
                {{ project.shortDescription }}
              </span>
            </div>
            <hr class="separator" />
            <div
              class="aprender-mas"
              routerLink="../proyecto/{{
                project.name.replace('-', ' ').replace(' ', '-').toLowerCase()
              }}"
            >
              <p>
                <span class="aprender-mas-accion"
                  >SABER MÁS <i class="fas fa-caret-right aprender-mas-icon"></i
                ></span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="_project-information">
        <div class="_project-container">
          <div class="_project-category">
            <span>
              {{ project.category }}
            </span>
          </div>
          <div class="_project-name">
            <span>
              {{ project.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="projects" *ngIf="projects.length % 3 !== 0">
      <div class="project-image">
        <div class="project-information">
          <div class="project-container">
            <div class="project-name"></div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <div
    class="see-more-container"
    *ngIf="projects.length < allProjects.length && !click"
    @fadeIn
  >
    <div class="see-more" (click)="loadMore(); click = true">
      <span>Cargar más</span>
    </div>
  </div>
</div>
