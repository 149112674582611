import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
  ViewChildren,
  QueryList,
  Renderer2,
} from "@angular/core";
import { ActivatedRoute, Router, ParamMap, Scroll } from "@angular/router";
import { switchMap, map } from "rxjs/operators";
import { PortafolioService } from "../services/portafolio.service";
import { Portafolio } from "../../models/portafolio";
import { ContactComponent } from "../landing-components/contact/contact.component";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";

@Component({
  selector: "app-proyecto",
  templateUrl: "./proyecto.component.html",
  styleUrls: ["./proyecto.component.scss"],
})
export class ProyectoComponent implements OnInit, AfterViewInit {
  @ViewChild("description") description: ElementRef;
  @ViewChildren("images") divsImages: QueryList<ElementRef>;
  id: string;
  project: Portafolio;
  expandir = false;
  descriptionWidth: number;
  totalImages: number;
  images: any;
  imageHeight: number;
  offsetFirstImage = 0;
  offsetLastImage = 0;

  @HostListener("window:scroll", ["$event"])
  onScroll(event: Scroll) {
    if (this.offsetFirstImage > 0) {
      const scrollY = window.pageYOffset + window.innerHeight;
      if (
        scrollY > this.offsetFirstImage &&
        window.pageYOffset < this.offsetLastImage
      ) {
        const offset = scrollY - this.offsetFirstImage;
        let imageId = Math.floor(offset / this.imageHeight);
        if (imageId >= this.divsImages.toArray().length) {
          imageId = this.divsImages.toArray().length - 1;
        }
        this.divsImages.map((image, idx) => {
          if (idx > imageId) {
            return;
          }
          const el = image.nativeElement;
          this.renderer.setStyle(el, "animation-name", "fadeInUp");
          this.renderer.setStyle(el, "visibility", "visible");
        });
      }
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private portafolioService: PortafolioService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => (this.id = params["id"]));
    // console.log(this.id);
    this.getProjectByName(this.id.replace("-", " ").toLowerCase());
    this.totalImages = 20;
    // this.images = new Array(this.totalImages).fill("").map((image, idx) => {
    //   return this.project.repository + "img" + (idx + 1) + ".jpg";
    // });
    this.images = [];
    if (this.project) {
      this.images.push(this.project.repository + "img1.jpg");
    } else {
      this.router.navigate(["/"]);
    }
  }
  ngAfterViewInit() {
    window.scroll(0, 0);
    if (this.description) {
      this.descriptionWidth = this.description.nativeElement.scrollWidth;
    }
    this.calculateImages();
    this.divsImages.changes.subscribe((r) => {
      this.calculateImages();
    });
  }
  calculateImages() {
    setTimeout(() => {
      if (this.divsImages.toArray().length > 0) {
        this.imageHeight = this.divsImages.first.nativeElement.scrollHeight;
        this.offsetFirstImage = this.divsImages.first.nativeElement.offsetTop;
        this.offsetLastImage =
          this.divsImages.last.nativeElement.offsetTop + this.imageHeight;
        this.divsImages.map((image) => {
          const el = image.nativeElement;
          this.renderer.setStyle(el, "animation-name", "none");
          this.renderer.setStyle(el, "visibility", "hidden");
          this.onScroll(null);
        });
      }
    }, 0);
  }
  getProject(id: number) {
    this.portafolioService.getProjectByID(id).subscribe((project) => {
      this.project = project;
    });
  }
  getProjectByName(name: string) {
    this.portafolioService.getProjectByName(name).subscribe((project) => {
      this.project = project;
    });
  }
  getExpand() {
    this.expandir = !this.expandir;
    if (this.expandir) {
      this.description.nativeElement.parentElement.style.maxHeight =
        this.descriptionWidth + "px";
    } else {
      // this.description.nativeElement.parentElement.style.maxHeight = "0px";
    }
  }
  outOfLimit(idx: number) {
    this.images = Array.from(this.images).slice(0, idx - 1);
    // this.images = [];
  }
  successfulLoad(idx: number) {
    const newUrl = this.project.repository + "img" + (idx + 1) + ".jpg";
    // this.imageExits(newUrl, (exist) => {
    //   if (exist) {
    //     this.images.push(newUrl);
    //   }
    // });
    this.images.push(newUrl);
    // console.log(idx);
  }
  // imageExits(url, callback) {
  //   const img = new Image();
  //   img.onload = () => {
  //     callback(true);
  //   };
  //   img.onerror = () => {
  //     callback(false);
  //   };
  //   img.src = url;
  // }
}
